import Cookies from "js-cookie";

const Token = "ERPToken";
const PublicKey = "PublicKey";
const UserName = "UserName";
const UserId = "UserId";

// PublicKey相关
export function setPublicKey(key) {
    return Cookies.set(PublicKey, key);
}

export function getPublicKey() {
    return Cookies.get(PublicKey);
}

export function removePublicKey() {
    return Cookies.remove(PublicKey);
}


// Token相关
export function getToken() {
    return Cookies.get(Token);
}

export function setToken(token) {
    return Cookies.set(Token, token);
}

export function removeToken() {
    return Cookies.remove(Token);
}


// 用户名相关
export function getUserName() {
    return Cookies.get(UserName);
}

export function setUserName(userName) {
    return Cookies.set(UserName, userName);
}

export function removeUserName() {
    return Cookies.remove(UserName);
}


// 用户名Id相关
export function getUserNameId() {
    return Cookies.get(UserId);
}

export function setUserNameId(userId) {
    return Cookies.set(UserId, userId);
}

export function removeUserNameId() {
    return Cookies.remove(UserId);
}

const role = {
    state: {
        roleInfo: null, // 改菜单下所有权限
        routeList: [], // 路由信息
        taskDetailsMenuId: "1336238290999808001", // 任务详情弹窗Id
        taskDetailsRoleInfo: null, // 任务详情权限
        deviceDetailsMenuId: "1332133144662978562", // 设备详情页面Id
        deviceDetailsRoleInfo: null, // 设备详情页面权限
    },

    mutations: {
        setRoleInfo: (state, data) => {
            state.roleInfo = data;
        },
        setRouteList: (state, data) => {
            state.routeList = data;
        },
        setTaskDetailsRoleInfo: (state, data) => {
            state.taskDetailsRoleInfo = data[0] || null;
        },
        setDeviceDetailsRoleInfo: (state, data) => {
            state.deviceDetailsRoleInfo = data[0] || null;
        },
    },

    actions: {},
};

export default role;

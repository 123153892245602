export default {
    /**
     * @描述：获取cookie
     * @参数:cookieName 需要获取的cookie
     * @返回: cookie
     */
    getCookie(cookieName) {
        let strCookie = document.cookie;
        let arrCookie = strCookie.split("; ");
        for (let i = 0; i < arrCookie.length; i++) {
            let arr = arrCookie[i].split("=");
            if (cookieName === arr[0]) {
                return arr[1];
            }
        }
        return "";
    },

    /* 设置cookie */
    setCookie(key, val) {
        document.cookie = key + "=" + val;
    },

    /*
     * 获取url指定参数  获取search  非hash
     * */
    getParam: function (name) {
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        let r = location.search.substr(1).match(reg);
        if (r !== null) return unescape(decodeURI(r[2]));
        return null;
    },

    /*
     * 获取url指定参数 非hash  获取search
     * */
    getParamHash: function () {
        let lets = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (
            m,
            key,
            value
        ) {
            lets[key] = value;
        });
        return lets;
    },

    /*
     * 删除cookie
     * */
    delCookie(name) {
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        document.cookie =
            name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
    },

};

// import { Message } from "element-ui";
// import store from "../store/index";
import commonAPI from "@/api/commonAPI.js";
import systemManageApi from "@/api/systemManageApi.js";
import humanResourcesManageApi from "@/api/humanResourcesManageApi.js";
export default {
    /**
     * @描述：获取页面下拉数据  后台枚举数据
     * @参数:type 类型  field：页面数据对象（存下拉数据对象）  self：this指针  组件this
     * noDefault: 是否需要全部选项 有值就是不需要
     * @返回: Array 下拉数据
     */
    getSelectByType(type, field, self, noDefault = false) {
        return new Promise((resolve) => {
            commonAPI.getSelectByCode(type).then((res) => {
                let respData = noDefault
                    ? res.content
                    : [{ code: "", label: "全部" }].concat(res.content);
                resolve(respData);
                self[field] = respData;
            });
        });
    },
    /**
     * @描述：获取负责人下拉数据
     * @参数:type 类型  field：页面数据对象（存下拉数据对象）  self：this指针  组件this
     * noDefault: 是否需要全部选项 有值就是不需要
     * type: BUSINESS-商务负责人", SALE-售前负责人, PROJECT-项目负责人
     * @返回: Array 下拉数据
     */
    getPersonOpts(type, field, self, noDefault = false) {
        return new Promise((resolve) => {
            commonAPI.getPersonOpts(type).then((res) => {
                let respData = noDefault
                    ? res.content
                    : [{ code: "", label: "全部" }].concat(res.content);
                resolve(respData);
                self[field] = respData;
            });
        });
    },
    /**
     * @描述：获取字典项下拉数据
     * @参数:type 类型  field：页面数据对象（存下拉数据对象）  self：this指针  组件this
     * noDefault: 是否需要全部选项 有值就是不需要
     * type: BUSINESS-商务负责人", SALE-售前负责人, PROJECT-项目负责人
     * @返回: Array 下拉数据
     */
    getModule(type, field, self, noDefault = false) {
        return new Promise((resolve) => {
            commonAPI.getModuleCode(type).then((res) => {
                let data = res.content;
                let opt = data.map((val) => {
                    return { label: val["name"], code: val["value"] };
                });
                let respData = noDefault
                    ? opt
                    : [{ code: "", label: "全部" }].concat(opt);
                resolve(respData);
                self[field] = respData;
            });
        });
    },

    /**
     * @描述：获取页面下拉数据 后台字典数据
     * @code:type 字典编码  field：页面数据对象（存下拉数据对象）  self：this指针  组件this
     * noDefault: 是否需要全部选项 有值就是不需要
     * @返回: Array 下拉数据
     */
    getSelectByCode(code, field, self, noDefault = false) {
        return new Promise((resolve) => {
            commonAPI.getModuleCode(code).then((res) => {
                let respData = noDefault ? res.content : [{ id: "", name: "全部", value: "" }].concat(res.content);
                resolve(respData);
                self[field] = respData;
            });
        });
    },

    /**
     * @描述：获取职权列表数据
     * @参数: field：页面数据对象（存下拉数据对象）
     * self：this指针  组件this
     * param: 接口所需参数
     * noDefault: 是否需要全部选项 有值就是需要
     * @返回: Array 下拉数据
     */
    getAuthorityList(field, self, param = { page: 1, size: 200 }, noDefault = false) {
        return new Promise((resolve) => {
            systemManageApi.getAuthorityList(param).then((res) => {
                let resp = res.content.records.map(v => {
                    return {
                        code: v.id,
                        label: v.authorName,
                    }
                });
                let respData = noDefault
                    ? [{ code: "", label: "全部" }].concat(resp) : resp;
                resolve(respData);
                console.log(respData)
                self[field] = respData;
            });
        });
    },

    /**
     * @根据id查所属部门
     * @参数:id 用户id  field：页面数据对象（存下拉数据对象）  self：this指针  组件this
     * noDefault: 是否需要全部选项 有值就是不需要
     * @返回: Array 下拉数据
     */
    getPersonDept(id) {
        return new Promise((resolve) => {
            humanResourcesManageApi.getUserInfoByUserId(id).then((res) => {
                let respData = res.content ? { deptId: res.content.deptId, deptName: res.content.deptName } : null;
                console.log(respData);
                resolve(respData);
            });
        });
    },
};

// import { loginByUsername, logout, getUserInfo } from '@/api/login'

/*   后台枚举菜单选项   */
import commonAPI from "@/api/commonAPI.js";
const enums = {
    // namespaced: true, // 增加命名空间
    state: {
        AuthorDataType:null,//数据权限枚举  单选
        AuthorSectionType:null,//菜单下数据权限可选的部分数据权限，枚举列表  复选
    },

    mutations: {
        setAuthorDataType: (state, data) => {
            state.AuthorDataType = data;
        },

        setAuthorSectionType: (state, data) => {
            state.AuthorSectionType = data;
        },

    },

    actions: {
        // 获取数据权限枚举
        getAuthorDataType({ commit, state }) {
            return new Promise((resolve, reject) => {
                return state.AuthorDataType
                    ? resolve(state.AuthorDataType)
                    : commonAPI
                        .getSelectByCode('AuthorDataType')
                        .then((res) => {
                            commit("setAuthorDataType", res.content);
                            resolve(res.content);
                        })
                        .catch((error) => {
                            reject(error);
                        });
            });
        },

        // 菜单下数据权限可选的部分数据权限
        getAuthorSectionType({ commit, state }) {
            return new Promise((resolve, reject) => {
                return state.AuthorSectionType
                    ? resolve(state.AuthorSectionType)
                    : commonAPI
                        .getSelectByCode('AuthorSectionType')
                        .then((res) => {
                            commit("setAuthorSectionType", res.content);
                            resolve(res.content);
                        })
                        .catch((error) => {
                            reject(error);
                        });
            });
        },
    },
};

export default enums;

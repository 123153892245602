import axios from "axios";
// import { Message } from 'element-ui';
import qs from "qs";
// import router from '../../router';
// import { delCookie } from '#/js/cookie';
import {Message} from "element-ui";
import commonJs from "../../utils/common";
import {removePublicKey, removeToken} from "@/utils/auth";

axios.defaults.baseURL = window.configPath.baseUrl;
axios.defaults.withCredentials = true;
axios.defaults.timeout = 60 * 1000; //请求过期时间
let loginOuttipsStatus = true; //登录提示状态 避免登录失效 重复提示

const noTokenUrlArr = [
    "/base/login/publicKey",
    "/base/login/login",
    "/base/login/dingtalk-login",
    "/base/login/weixin-login",
]; //免token接口
// 请求拦截
axios.interceptors.request.use(
    (config) => {
        if (commonJs.getCookie("ERPToken")) {
            loginOuttipsStatus = true;
            config.headers["access-token"] = commonJs.getCookie("ERPToken"); // 添加token到请求头
        } else {
            if (noTokenUrlArr.indexOf(config.url) === -1) {
                httpFn.noTokenLoginout();
            }
        }
        return config;
    },
    (error) => {
        Promise.error(error);
    }
);
// 响应拦截
axios.interceptors.response.use(
    (response) => {
        if (response.status === 200 && response.data.code === 200) {
            return Promise.resolve(response);
        } else if (response.status === 401) {
            //token过期
            httpFn.noTokenLoginout();
        } else {
            Message({
                message:
                    response.message || response.data.message || "远程请求失败",
                type: "error",
            });
        }
        return Promise.reject(response);
    },
    (err) => {
        let errStr = err.toString();
        errStr.includes("401")
            ? httpFn.noTokenLoginout()
            : Message({message: "远程请求失败", type: "error"});
    }
);

let httpFn = {
    //无token时的登录失效
    noTokenLoginout() {
        removeToken();
        removePublicKey();
        loginOuttipsStatus &&
        Message({
            message: "登录失效！请重新登录！",
            type: "error",
        });
        loginOuttipsStatus = false;
        location.href = `${location.origin}/#/login`;
        window.location.reload()
    },
};

/* POST FORMDATA格式参数 */
export function postForm(url, param) {
    let menuId = window.localStorage.getItem("routerId"); //当前的菜单id  接口做权限过滤使用
    let par = {menuId: menuId};
    if (param) {
        par = param
            ? Object.assign({}, param, {
                menuId: menuId,
            })
            : param;
    }
    return new Promise((resolve, reject) => {
        axios
            .post(url, qs.stringify(par), {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err.data);
            });
    });
}

/* 普通POST  */
export function post(url, param) {
    let menuId = window.localStorage.getItem("routerId"); //当前的菜单id  接口做权限过滤使用
    let params = param
        ? Object.assign({}, param, {
            menuId: menuId,
        })
        : param;
    return new Promise((resolve, reject) => {
        axios
            .post(url, params)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err.data);
            });
    });
}

/* 上传文件POST  */
export function postFile(url, param) {
    let menuId = window.localStorage.getItem("routerId"); //当前的菜单id  接口做权限过滤使用
    let params = param
        ? Object.assign({}, param, {
            menuId: menuId,
        })
        : param;
    return new Promise((resolve, reject) => {
        axios({
            url: url,
            method: 'post',
            data: params,
            processData: false, // 告诉axios不要去处理发送的数据(重要参数)
            // contentType: false, // 告诉axios不要去设置Content-Type请求头
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err.data);
            });
    });
}

/* POST  JSON字符串格式数据  */
export function postJson(url, param, headers) {
    let menuId = window.localStorage.getItem("routerId"); //当前的菜单id  接口做权限过滤使用
    let params = param;
    if (!Array.isArray(param)) {
        params = param
            ? Object.assign(
                {},
                {
                    menuId: menuId,
                },
                param
            )
            : param;
    }
    if (url.match(/.*?\/table$/)) {
        if (url.indexOf("/reserveproject/reserve-project/table") !== -1) {
            url = `${url}/${window.localStorage.getItem("reserveProjectTabId")}`
        } else {
            let getMenu = () => {
                let tabId = window.localStorage.getItem("tabId");
                if (tabId && tabId !== 'null') {
                    return tabId
                } else {
                    return menuId
                }
            }
            url = `${url}/${getMenu()}`
        }
    }
    return new Promise((resolve, reject) => {
        axios
            .post(url, params, {
                headers: headers || {"Content-Type": "application/json"},
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

/* 普通get */
export function get(urls, param) {
    let menuId = window.localStorage.getItem("routerId"); //当前的菜单id  接口做权限过滤使用
    /* 合并菜单id */
    let url = param ? urls : urls + "?menuId=" + menuId;
    let params = param
        ? Object.assign({}, param, {
            menuId: menuId,
        })
        : param;
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params,
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err.data);
            });
    });
}

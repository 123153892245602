import publicGetData from "./publicGetData/publicGetData";
import encryption from "./encryption/AES";
import rsa from "./encryption/rsa";
import file from "./file/file";
import date from "./date/publicDate";
import task from "./task/task";
import map from "./map/map";
import number from "./number/number";
import filter from "./filter/filter";
import getParamFn from "./getParam/getParam";
import role from "./role/role";
import base from "./base/base";
export default {
    ...publicGetData,
    ...encryption,
    ...file,
    ...date,
    ...task,
    ...map,
    ...number,
    ...filter,
    ...getParamFn,
    ...role,
    ...rsa,
    ...base
};

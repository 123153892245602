// import request from '@/utils/request'
import {
    get,
    // post,
    // postForm,
    // postJson,
} from "./http/http";

const userRoleApi = {
    // 获取登录账号菜单
    getCurrentUserMenu() {
        return get("/base/role-menu/findCurrentUserMenu");
    },
    // 根据菜单Id查询相应功能权限
    getCurrentButtonByMenuId(menuId) {
        return get(`/base/role-menu/findCurrentButtonByMenuId/${menuId}`);
    }
};

export default userRoleApi;

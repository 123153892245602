
import { Message } from "element-ui";
export default {
    /**
     * @描述：地理编码（地址 -> 坐标）
     * @参数:address:地名
     * @参数:map: MAP对象
     * @返回: 经纬度解析对象
     */
    getGeocodes(address) {
        return new Promise((resolve,reject) => {
            if(!address){return resolve(null);}
            let map = new AMap.Geocoder();
            map.getLocation(address, function(status, result) {
                if (status === "complete" && result.geocodes.length) {
                    var lnglat = result.geocodes[0].location;
                    resolve(lnglat);
                } else {
                    reject(address);
                    Message({type:"error",message:`无法解析${address}经纬度，请选择正确地址`})
                }
            });
        });
    },
};

import Vue from "vue";
import $ from "jquery";
Vue.directive("role", {
    componentUpdated(el, binding) {
        const type = binding.value || {}; // 接受指令传入的值
        if(binding.value === null){return $(el).hide()} // 匹配不到相应的权限数据  默认为移除节点
        if(type.checked){return $(el).show()} // 匹配到相应数据时 默认显示
        if(!type || !type.hasOwnProperty('checked') || type.checked || !binding.value){return} //默认选中的话显示  无匹配数据时不操作
        switch (type.noAuthorMethod){ //无权限时进行  hidden  或者 disable操作
        // case "HIDDEN":el.parentNode ? el.parentNode.removeChild(el) : el.removeChild(); // 移除节点
        case "HIDDEN":$(el).remove(); // 移除节点
            break;
        case "NOT_EDITABLE":roleFn.setDisalbe(el); // 不可编辑
            break;
        default:
            break;
        }
    },
});

//操作方法
let roleFn = {
    setDisalbe(el){
        $(el).attr("disabled","disabled").css({"background-color":"#F5F7FA","border-color":"#E4E7ED","color":"#C0C4CC","cursor":"not-allowed"}).find('input,select,button,textarea').attr("disabled","disabled").css({"background-color":"#F5F7FA","border-color":"#E4E7ED","color":"#C0C4CC","cursor":"not-allowed"})
    }
};

// 任务管理

export const taskManage = [
    {
        path: "/taskManage",
        component: () => import("@/views/layout/Layout"),
        redirect: "/taskManage",
        meta: {
            title: "任务管理",
            icon: "icon_taskManage",
        },
        children: [
            {
                path: "myTask",
                component: () => import("@/views/taskManage/myTask"),
                name: "myTask",
                meta: {
                    title: "我的任务",
                },
            },
            {
                path: "depTask",
                component: () => import("@/views/taskManage/depTask"),
                name: "depTask",
                meta: {
                    title: "部门任务",
                },
            },
            {
                path: "itemTask",
                component: () => import("@/views/taskManage/itemTask"),
                name: "itemTask",
                meta: {
                    title: "项目任务",
                },
            },
            {
                path: "hoursViewDetails",
                component: () => import("@/views/taskManage/hoursViewDetails"),
                name: "hoursViewDetails",
                meta: {
                    title: "工时统计视图",
                },
            },
        ],
    },
    // 总视图
    {
        path: "/generalView",
        component: () => import("@/views/layout/Layout"),
        redirect: "/generalView",
        meta: {
            title: "总视图",
            icon: "icon_generalView",
        },
        children: [
            {
                path: "index",
                component: () => import("@/views/generalView/index"),
                name: "myTask",
                meta: {
                    title: "总视图",
                },
            },
        ],
    },
    // 成本管理
    {
        path: "/costManage",
        component: () => import("@/views/layout/Layout"),
        redirect: "/costManage",
        meta: {
            title: "成本管理",
            icon: "icon_costManage",
        },
        children: [
            {
                path: "index",
                component: () => import("@/views/costManage/index"),
                name: "myTask",
                meta: {
                    title: "成本管理",
                },
            },
        ],
    },
];

import store from "@/store/index";
import userRoleApi from "@/api/userRoleApi.js";
export default {
    /**
     * @描述：权限    获取当前详情页是否存在  tab方式
     * @参数:roleData：权限列表数据
     * @参数:name：需要查询的 页面/Tab 名称
     * @返回: Array
     */
    tabIsAlive(roleData = [], name = "") {
        return roleData.filter((v) => v.detailMenu.title === name)[0] || null;
    },

    /**
     * @描述：权限    获取对应权限
     * @参数:detailRole   详情页对应的权限列表   name:需要查询的权限名称
     * @返回: boolean
     */
    getBtnRoles(detailRole = {}, name = "") {
        if (!detailRole || !detailRole.hasOwnProperty("buttonMenus")) {
            return null;
        }
        let rolebtn =
            detailRole.buttonMenus.filter((v) => v.title === name)[0] || null;
        return rolebtn
            ? Object.assign({}, rolebtn, {
                noAuthorMethod: rolebtn.noAuthorMethod.name,
            })
            : rolebtn;
    },
    /**
     * @描述：权限    是否显示按钮 不严格的
     * @参数:detailRole   详情页对应的权限列表   name:需要查询的权限名称
     * @返回: boolean 后台权限null的时候返回true
     */
    isShowBtn(detailRole = {}, name = "") {
        if (!detailRole) {
            return false
        }
        if (!detailRole || !detailRole.hasOwnProperty("buttonMenus")) {
            return true;
        }
        let rolebtn =
            detailRole.buttonMenus.filter((v) => v.title === name)[0] || true;
        return rolebtn ? Object.assign({}, rolebtn).checked : false;
    },
    /**
     * @描述：权限    是否显示按钮 严格的
     * @参数:detailRole   详情页对应的权限列表   name:需要查询的权限名称
     * @返回: boolean 后台权限null的时候返回true
     */
    isShowBtnStrict(detailRole = {}, name = "") {
        if (!detailRole || !detailRole.hasOwnProperty("buttonMenus")) {
            return false;
        }
        let rolebtn =
            detailRole.buttonMenus.filter((v) => v.title === name)[0] || true;
        return rolebtn ? Object.assign({}, rolebtn).checked : true;
    },
    /**
     * @描述：根据name设置对应tab的id
     * @参数:detailRole   详情页对应的权限列表   name:需要查询的权限名称
     * @返回: 无
     */
    setTabId(detailRole = {}, name = "") {
        window.localStorage.setItem("tabId", null)
        let rolebtn =
            detailRole.buttonMenus.filter((v) => v.title === name)[0];
        window.localStorage.setItem("tabId", rolebtn ? rolebtn.id : null)
    },
    /**
     * @描述：根据name返回对应tab的id
     * @参数:detailRole   详情页对应的权限列表   name:需要查询的权限名称
     * @返回: id
     */
    getTabId(detailRole = {}, name = "") {
        let rolebtn =
            detailRole.buttonMenus.filter((v) => v.title === name)[0];
        return rolebtn ? rolebtn.id : null
    },
    /**
     * @描述：根据nama数组返回第一个有权限的tab的id
     * @参数:detailRole   详情页对应的权限列表   name:需要查询的权限名称
     * @返回: id
     */
    getFistTabId(detailRole = {}, name = []) {
        // TODO i起始值应该为0才对，不知道是不是因为原始需求原因，从1开始
        for (var i = 0; i <= name.length; i++) {
            let filter = detailRole.buttonMenus.filter((v) => v.title === name[i]);
            let rolebtn = filter.length && filter[0]
            if (rolebtn && rolebtn.checked) {
                window.localStorage.setItem("tabId", rolebtn ? rolebtn.id : null)
                break;
            }
        }
    },
    /**
     * @描述： 获取当前页面的可操作权限
     */
    getCurrentButtonByMenuId(id) {
        userRoleApi.getCurrentButtonByMenuId(id).then((res) => {
            store.commit("setRoleInfo", res.content);
        });
    },
    /**
     * @描述： 获取任务详情弹窗的可操作权限
     */
    getTaskDetailsRole(id) {
        userRoleApi.getCurrentButtonByMenuId(id).then((res) => {
            store.commit("setTaskDetailsRoleInfo", res.content);
        });
    },
    /**
     * @描述： 获取设备详情页面的可操作权限
     */
    getDeviceDetailsRole(id) {
        userRoleApi.getCurrentButtonByMenuId(id).then((res) => {
            store.commit("setDeviceDetailsRoleInfo", res.content);
        });
    },
}
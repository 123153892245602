// import request from '@/utils/request'
import {get, post,} from "./http/http";

const httpServer = {
    // 获取publickey
    getPublicKey(){
        return post("/base/login/publicKey")
    },
    // 登录
    loginByUsername(params) {
        return post("/base/login/login", params);
    },
    // 退出登录
    logout(params) {
        return get("/base/login/logout", params);
    },
    // 获取用户信息
    getUserInfo(params) {
        return get("/base/login/userinfo", params);
    },
    //钉钉扫码登录，根据临时授权码登录
    loginDingTalk(code, state) {
        return get("/base/login/dingtalk-login", {code, state})
    },
    //微信扫码登录
    loginWx(code, state) {
        return get("/base/login/weixin-login", {code, state})
    },
};

export default httpServer;

// 项目管理

export const projectManagementRouter = [
    // 项目机会管理
    {
        path: "/reserveProject",
        component: () => import("@/views/layout/Layout"),
        redirect: "/reserveProject",
        meta: {
            title: "项目机会管理",
            icon: "icon_deviceManage",
        },
        children: [
            {
                path: "index",
                component: () => import("@/views/reserveProject/index"),
                name: "reserveProject",
                meta: {
                    title: "项目机会管理",
                    keepAlive: true, // 需要被缓存
                },
            },
            {
                path: "reserveProjectRecord",
                component: () =>
                    import("@/views/reserveProject/reserveProjectRecord"),
                name: "reserveProjectRecord",
                meta: {
                    title: "项目机会记录",
                    keepAlive: false, // 需要被缓存
                },
            },
            {
                path: "reserveProjectDetails",
                component: () =>
                    import("@/views/reserveProject/reserveProjectDetails"),
                name: "reserveProjectDetails",
                hidden: true,
                meta: {
                    title: "项目机会详情",
                },
            },
        ],
    },
    // 项目管理
    {
        path: "/projectManage",
        component: () => import("@/views/layout/Layout"),
        redirect: "/projectManage",
        meta: {
            title: "项目管理",
            icon: "icon_deviceManage",
        },
        children: [
            {
                path: "roleManagement",
                component: () => import("@/views/projectManage/roleManagement"),
                name: "roleManagement",
                meta: {
                    title: "项目角色",
                },
            },
            {
                path: "index",
                component: () => import("@/views/projectManage/index"),
                name: "projectManage",
                meta: {
                    title: "项目管理",
                    keepAlive: true, // 需要被缓存
                },
            },
            {
                path: "projectManageDetails",
                component: () =>
                    import("@/views/projectManage/projectManageDetails"),
                name: "projectManageDetails",
                hidden: true,
                meta: {
                    title: "项目管理详情",
                },
            },
            {
                path: "archivedItems",
                component: () => import("@/views/projectManage/archivedItems"),
                name: "archivedItems",
                meta: {
                    title: "归档项目",
                    keepAlive: false, // 需要被缓存
                },
            },
            // 合并-采购管理
            {
                path: "purchaseManage",
                component: () =>
                    import("@/views/purchaseManage/purchaseManage"),
                name: "purchaseManage",
                meta: {
                    title: "采购管理",
                },
            },
            {
                path: "purchaseDetails",
                component: () =>
                    import("@/views/purchaseManage/purchaseDetails"),
                name: "purchaseDetails",
                hidden: true,
                meta: {
                    title: "采购详情",
                },
            },
            {
                path: "deviceDetails",
                component: () => import("@/views/purchaseManage/deviceDetails"),
                name: "deviceDetails",
                hidden: true,
                meta: {
                    title: "设备详情",
                },
            },
        ],
    },
    // 实施管理
    {
        path: "/implementationManage",
        component: () => import("@/views/layout/Layout"),
        redirect: "/implementationManage",
        meta: {
            title: "实施管理",
            icon: "icon_deviceManage",
        },
        children: [
            {
                path: "index",
                component: () => import("@/views/implementationManage/index"),
                name: "implementationManage",
                meta: {
                    title: "实施管理",
                    keepAlive: false, // 需要被缓存
                },
            },
            {
                path: "archivingPlan",
                component: () =>
                    import("@/views/implementationManage/archivingPlan"),
                name: "archivingPlan",
                meta: {
                    title: "归档计划",
                    keepAlive: false, // 需要被缓存
                },
            },
            {
                path: "implementationManageDetails",
                component: () =>
                    import(
                        "@/views/implementationManage/implementationManageDetails"
                    ),
                name: "implementationManageDetails",
                hidden: true,
                meta: {
                    title: "实施管理详情",
                },
            },
        ],
    },
    // 合同管理
    {
        path: "/contractManage",
        component: () => import("@/views/layout/Layout"),
        redirect: "/contractManage",
        meta: {
            title: "合同管理",
            icon: "icon_deviceManage",
        },
        children: [
            {
                path: "index",
                component: () => import("@/views/contractManage/index"),
                name: "contractManage",
                meta: {
                    title: "合同管理",
                    keepAlive: false, // 需要被缓存
                },
            },
            {
                path: "contractRecord",
                component: () =>
                    import("@/views/contractManage/contractRecord"),
                name: "contractRecord",
                meta: {
                    title: "合同记录",
                    keepAlive: false, // 需要被缓存
                },
            },
            {
                path: "contractManageDetail",
                component: () =>
                    import(
                        "@/views/contractManage/contractManage/contractManageDetail"
                    ),
                name: "contractManageDetail",
                hidden: true,
                meta: {
                    title: "合同管理详情",
                },
            },
        ],
    },
    // 模板管理
    {
        path: "/templateManage",
        component: () => import("@/views/layout/Layout"),
        redirect: "/templateManage",
        meta: {
            title: "模板管理",
            icon: "icon_deviceManage",
        },
        children: [
            {
                path: "index",
                component: () => import("@/views/templateManage/index"),
                name: "templateManage",
                meta: {
                    title: "模板管理",
                },
            },
        ],
    },
    //系统用户
    {
        path: "/systemManage",
        component: () => import("@/views/layout/Layout"),
        redirect: "/systemManage",
        meta: {
            title: "系统用户",
            icon: "icon_deviceManage",
        },
        children: [
            {
                path: "userManagement",
                component: () => import("@/views/systemManage/userManagement"),
                name: "userManagement",
                meta: {
                    title: "用户管理",
                },
            },
            {
                path: "roleManagement",
                component: () => import("@/views/systemManage/roleManagement"),
                name: "roleManagement",
                meta: {
                    title: "角色管理",
                },
            },
            {
                path: "dataPermissionsManagement",
                component: () =>
                    import("@/views/systemManage/dataPermissionsManagement"),
                name: "dataPermissionsManagement",
                meta: {
                    title: "数据权限管理",
                },
            },
            {
                path: "organManagement",
                component: () => import("@/views/systemManage/organManagement"),
                name: "organManagement",
                meta: {
                    title: "组织架构管理",
                },
            },
            {
                path: "menuManagement",
                component: () => import("@/views/systemManage/menuManagement"),
                name: "menuManagement",
                meta: {
                    title: "菜单功能管理",
                },
            },
            {
                path: "notificationConfig",
                component: () => import("@/views/systemManage/notificationConfig"),
                name: "notificationConfig",
                meta: {
                    title: "通知配置",
                },
            },
        ],
    },
];

// import { Message } from "element-ui";
// import store from "../store/index";
import commonAPI from "@/api/commonAPI.js";
export default {
    /**
     * @描述 切换任务完成状态
     * @参数 id: 要操作的任务id主键
     * @参数 status: 关闭传false 打开传true
     * @返回 Array 下拉数据
     */
    changeTaskStatus(id, status=true) {
        return new Promise((resolve,reject) => {
            commonAPI.changeTaskStatus(id,status).then((res) => {
                resolve(res);
            }).catch((res) => {
                reject(res);
            });
        });
    },
};

// 单独需要弹出显示的单页面

export const blankPageRouter = [
    {
        path: "/supplierDetail_blank",
        component: () =>
            import("@/views/baseInfoManage/supplierDetail"),
        name: "supplierDetail",
        hidden: true,
        meta: {
            title: "供应商信息详情",
        },
    },
    {
        path: "/systemDetail_blank",
        component: () => import("@/views/baseInfoManage/systemDetail"),
        name: "softwareInfoManage",
        hidden: true,
        meta: {
            title: "软件版本管理",
        },
    },
];

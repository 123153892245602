import JSEncrypt from 'jsencrypt';
import store from '@/store'
import jsrsasign from 'jsrsasign'
export default {

    //获取 RSA 密钥对
    getRsaKeys() {
        // const that = this;
        return new Promise((resolve) => {
            let key = jsrsasign.KEYUTIL.generateKeypair('RSA', 1024);
            let publicKey = jsrsasign.KEYUTIL.getPEM(key.pubKeyObj).replace("-----BEGIN PUBLIC KEY-----","").replace("-----END PUBLIC KEY-----","").trim();
            let privateKey = jsrsasign.KEYUTIL.getPEM(key.prvKeyObj, "PKCS1PRV").replace("-----END RSA PRIVATE KEY-----","").replace("-----BEGIN RSA PRIVATE KEY-----","").trim();
            resolve({privateKey,publicKey});
        })
    },
    /**
     * @描述：RSA加密
     * @参数: publicKey： 公钥
     * @参数: str： 需要加密的字符串
     * @返回: String 加密后的字符串
     */
    rsaEncryption(str,publicKey = store.state.user.salaryKey.publicKey){
        // return str
        // if((!str || !publicKey) && str !== 0 && str !== "0.00"){return str }

        if(!publicKey || str === null || str === "" || str === undefined ){return str }
        //new一个对象
        let encrypt = new JSEncrypt();
        //设置公钥
        encrypt.setPublicKey(publicKey);
        //password是要加密的数据,此处不用注意+号,因为rsa自己本身已经base64转码了,不存在+,全部是二进制数据
        let result = encrypt.encrypt(typeof(str) === "string" ? str : str.toString());
        return result
    },

    /**
     * @描述：RSA解密
     * @参数: privateKey： 私钥
     * @参数: str： 加密的字符串
     * @参数: save2 默认保留两位小数
     * @返回: String 解密后的字符串
     */
    rsaDecrypt(str,privateKey = store.state.user.salaryKey.privateKey,save2 = true){
        if(!str || !privateKey){return str || ''}
        // 新建JSEncrypt对象
        let decrypt = new JSEncrypt();
        // 设置私钥
        decrypt.setPrivateKey(privateKey);
        // 解密数据
        let result = decrypt.decrypt(str);
        if(save2){
            return parseFloat(result) === 0 ? Number(0).toFixed(2) : typeof result ==='number' ? result.toFixed(2) : result || "";
        } else {
            return result === 0 ? 0 : result || "";
        }
    },

    /**
     * @描述：map加密 针对map对象字段加密
     * @参数: data : MAP数据{}
     * @参数: notNeedPwd： 不需要加密的白名单  Array
     * @参数: serverPublicKey： 公钥加密  String
     */
    encryMapData(data,notNeedPwd,serverPublicKey){
        let formKeyArr = Object.keys(data);
        formKeyArr.forEach((item) => {
            if (notNeedPwd.indexOf(item) === -1) {
                // 需要加密
                data[item] = this.rsaEncryption(
                    data[item],
                    serverPublicKey
                );
            }
        });
        return data;
    },

    /**
     * @描述：map加密 针对map对象字段加密
     * @参数: data : MAP数据{}
     * @参数: fieldArr 需要加密的名单  Array
     * @参数: serverPublicKey： 公钥加密  String
     */
    encryMapDataByArr(data,fieldArr,serverPublicKey){
        let formKeyArr = Object.keys(data);
        formKeyArr.forEach((item) => {
            if (fieldArr.indexOf(item) > -1) {
                // 需要加密
                data[item] = this.rsaEncryption(
                    data[item],
                    serverPublicKey
                );
            }
        });
        return data;
    },

    /**
     * @描述：map解密 针对map对象加密字段解密
     * @参数: data : MAP数据{}
     * @参数: fieldArr： 需要解密的字段数组  Array
     */
     decryMapData(data,fieldArr){
        let formKeyArr = Object.keys(data);
        formKeyArr.forEach((item) => {
            if (fieldArr.indexOf(item) > -1) {
                // 需要解密
                data[item] = this.rsaDecrypt(
                    data[item]
                );
            }
        });
        return data;
    },
};

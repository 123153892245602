// import request from '@/utils/request'
import {
    get,
    // post,
    // postForm,
    postJson,
} from "./http/http";

const systemManageApi = {
    // 查询公司组织架构列表
    getDepartmentTable(params) {
        return postJson("/base/department/list", params);
    },

    // 组织架构保存
    saveDepartment(params) {
        return postJson("/base/department/save", params);
    },

    // 删除架构保存
    deleteDepartment(id) {
        return get(`/base/department/deleteById/${id}`);
    },

    //根据条件查询用户列表
    getUserTable(params) {
        return postJson(`/base/user/table`, params);
    },

    //重置用户密码
    resetUserPwd(id) {
        return postJson(`/base/user/resetPwd/${id}`);
    },

    //用户数据更新/保存
    saveUserInfo(params) {
        return postJson(`/base/user/save`, params);
    },

    //用户数据删除
    deleteUserInfo(id) {
        return get(`/base/user/deleteById/${id}`);
    },

    //获取角色管理列表
    getRoleTable(params, menuId) {
        return postJson(`/base/role/table/${menuId || null}`, params);
    },

    //保存角色
    saveRoleInfo(params) {
        return postJson(`/base/role/save`, params);
    },

    //删除角色
    deleteRoleInfo(id) {
        return get(`/base/role/deleteById/${id}`);
    },

    //菜单列表树形结构
    getMenuTable(params) {
        return get(`/base/menu/list`, params);
    },

    //菜单保存
    saveMenuInfo(params) {
        return postJson(`/base/menu/save`, params);
    },

    //删除菜单
    deleteMenuInfo(id) {
        return get(`/base/menu/deleteById/${id}`);
    },

    //获取权限列表
    getAuthorList(params) {
        return get(`/base/role-menu/authorList`, params);
    },

    //保存权限
    saveAuthor(params) {
        return postJson(`/base/role-menu/save`, params);
    },

    // 项目名称模糊搜索
    searchItemList(params) {
        return postJson(`/project/project/projectSearch`, params);
    },

    // 查询职权列表
    getAuthorityList(params) {
        return postJson(`/authority/authority/table`, params);
    },
    // 新增职权
    addAuthority(params) {
        return postJson(`/authority/authority/save`, params);
    },
    // 编辑职权
    editAuthority(params) {
        return postJson(`/authority/authority/edit`, params);
    },
    // 删除职权
    deleteAuthority(id) {
        return get(`/authority/authority/delete/${id}`);
    },

    // 查询职权数据权限列表
    findByAuthorityId(params) {
        return get(`/authority/authority-data/findByAuthorityId`, params);
    },

    // 保存或编辑职权数据权限功能
    saveAuthorityData(params) {
        return postJson(`/authority/authority-data/save`, params);
    },
};

export default systemManageApi;

<template>
    <div id="app">
        <router-view />
        <!-- <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view> -->
        <div class="testLine"></div>
    </div>
</template>

<script>
export default {
    name: "app",
    data() {
        return {
            cashViews: [],
        };
    },
    components: {},
};
</script>

<style>
.testLine {
    display: none;
    position: fixed;
    left: 0;
    bottom: 20px;
    width: 100%;
    height: 1px;
    background: #f00;
}
</style>

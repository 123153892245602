// import { loginByUsername, logout, getUserInfo } from '@/api/login'
import commonAPI from "@/api/commonAPI.js";
const user = {
    // namespaced: true, // 增加命名空间
    state: {
        payMany: null, // 已付款、收款金额
        allMoney: null, //合计金额
        contractMoney: null, //合同金额
        connectMoney: null, //关联项目分配总金额
        subTotalMoney: null, //剩余分配金额
        providerOrCustomerInfo: null, //客户、供应商信息
        remainMany: null, //收付款记录 剩余金额
        isSaveRecord: null, //新增收付款记录触发  更新收付款计划列表状态
        jsonData: null,
        companyAccount: null, //公司账户信息
        refreshPlan:null, //刷新收付款计划
    },

    mutations: {
        setPayMany: (state, money) => {
            state.payMany = money;
        },
        setAllMoney: (state, money) => {
            state.allMoney = money;
        },
        setSubTotalMoney: (state, money) => {
            state.subTotalMoney = money;
        },
        setProviderOrCustomerInfo: (state, info) => {
            state.providerOrCustomerInfo = info;
        },
        setRemainMany: (state, money) => {
            state.remainMany = money;
        },
        setIsSaveRecord: (state, num) => {
            state.isSaveRecord = num;
        },
        setjsonData: (state, json) => {
            state.jsonData = json;
        },
        setCompanyAccount: (state, conpany) => {
            state.companyAccount = conpany;
        },
        setContractMoney: (state, money) => {
            state.contractMoney = parseFloat(money);
        },
        setRefreshPlan: (state, plan) => {
            state.refreshPlan = plan;
        },
    },

    actions: {
        // 获取公司账户信息
        getCompamyAccount({ commit, state }) {
            return new Promise((resolve, reject) => {
                return state.companyAccount
                    ? resolve(state.companyAccount)
                    : commonAPI
                        .getCompanyAccount()
                        .then((res) => {
                            commit("setCompanyAccount", res.content);
                            resolve(res.content);
                        })
                        .catch((error) => {
                            reject(error);
                        });
            });
        },
    },
};

export default user;

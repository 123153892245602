// import Vue from 'vue'
import moment from "moment"; //日期插件

export default {
    /**
     * @描述：计算两个时间之间的相差时间  年-月
     * @参数:time1:时间1  YYYY-MM-DD
     * @参数:time1:时间2  YYYY-MM-DD
     * @返回: 时间 YYYY-MM-DD
     */
    getDiffDate (time1, time2) {
        return this.setFormatDateBystamp(moment(time1).diff(moment(time2)))
    },

    /**
     * @描述：时间戳转换时间长短 非日期  时间单位
     * @参数: stamp：时间戳
     * @返回: 时间 X年X月X日
     */

    setFormatDateBystamp(stamp){
        if(!stamp){return ""}
        let day = parseInt(stamp/1000/60/60/24);
        let y = parseInt(day/365) // 年
        let m = y > 0 ? parseInt((day%365)/30) : parseInt(day/30); // 月
        // let d = day - (y*365) - (m*30)// 日
        return day < 30 ? (day+'天') : (y ? y+'年' : '') + (m ? m+'个月' : '')
    },

    /*
     * 获得当前日期 YYY-mm-DD
     * */
    nowTime: function() {
        let now = new Date();
        let year = now.getFullYear(); //得到年份
        let month = now.getMonth(); //得到月份
        let date = now.getDate(); //得到日期
        month = month + 1;
        if (month < 10) month = "0" + month;
        if (date < 10) date = "0" + date;
        let time = "";
        time = year + "-" + month + "-" + date;
        return time;
    },
    /**
     * 时间比较
     * beginTime = "2020-12-21 00:00:00";
     * endTime = "2020-12-21 00:00:01";
     */
    compareTime: function(beginTime, endTime) {
        var beginTimes = beginTime.substring(0, 10).split("-");
        var endTimes = endTime.substring(0, 10).split("-");

        beginTime =
            beginTimes[1] +
            "-" +
            beginTimes[2] +
            "-" +
            beginTimes[0] +
            " " +
            beginTime.substring(10, 19);
        endTime =
            endTimes[1] +
            "-" +
            endTimes[2] +
            "-" +
            endTimes[0] +
            " " +
            endTime.substring(10, 19);
        var a = (Date.parse(endTime) - Date.parse(beginTime)) / 3600 / 1000;
        if (a < 0) {
            return "<";
        } else if (a > 0) {
            return ">";
        } else if (a === 0) {
            return "=";
        } else {
            return "exception";
        }
    },

    /**
     * @描述：时间戳转换
     * @参数:timestamp 时间戳
     * @返回:
     */
    timestampToTime(timestamp) {
        let date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let Y = date.getFullYear() + "-";
        let M =
            (date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1) + "-";
        let D = date.getDate() + " ";
        return timestamp ? Y + M + D : "";
    },
}

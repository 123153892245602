import Cookies from "js-cookie";

const app = {
    state: {
        sidebar: {
            opened: Cookies.get("sidebarStatus")
                ? !!+Cookies.get("sidebarStatus")
                : true,
            withoutAnimation: false,
        },
        device: "desktop",
        language: Cookies.get("language") || "en",
        size: Cookies.get("size") || "medium",
        userPosition: [
            {
                label: "技术人员",
                value: "TECH",
            },
            {
                label: "销售",
                value: "SALE",
            },
            {
                label: "经理",
                value: "MANAGER",
            },
        ],
        refreshProvideOrCust:null,//刷新基础信息管理的客户/供应商树
    },
    mutations: {
        TOGGLE_SIDEBAR: (state) => {
            state.sidebar.opened = !state.sidebar.opened;
            state.sidebar.withoutAnimation = false;
            if (state.sidebar.opened) {
                Cookies.set("sidebarStatus", 1);
            } else {
                Cookies.set("sidebarStatus", 0);
            }
        },
        CLOSE_SIDEBAR: (state, withoutAnimation) => {
            Cookies.set("sidebarStatus", 0);
            state.sidebar.opened = false;
            state.sidebar.withoutAnimation = withoutAnimation;
        },
        TOGGLE_DEVICE: (state, device) => {
            state.device = device;
        },
        SET_LANGUAGE: (state, language) => {
            state.language = language;
            Cookies.set("language", language);
        },
        SET_SIZE: (state, size) => {
            state.size = size;
            Cookies.set("size", size);
        },
        SET_REFRESHPROVIDE : (state, num) => {
            state.refreshProvideOrCust = num;
        }
    },
    actions: {
        toggleSideBar({ commit }) {
            commit("TOGGLE_SIDEBAR");
        },
        closeSideBar({ commit }, { withoutAnimation }) {
            commit("CLOSE_SIDEBAR", withoutAnimation);
        },
        toggleDevice({ commit }, device) {
            commit("TOGGLE_DEVICE", device);
        },
        setLanguage({ commit }, language) {
            commit("SET_LANGUAGE", language);
        },
        setSize({ commit }, size) {
            commit("SET_SIZE", size);
        },
    },
};

export default app;

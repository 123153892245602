import commonAPI from "@/api/commonAPI.js";
import { Message } from "element-ui";
import axios from 'axios'

export default {
    /**
     * @描述：下载文件
     * @参数:id:文件id
     * @返回: 文件
     */
    fileDownLoadByid(id) {
        let getCookie = function (cookieName) {
            let strCookie = document.cookie;
            let arrCookie = strCookie.split("; ");
            for (let i = 0; i < arrCookie.length; i++) {
                let arr = arrCookie[i].split("=");
                if (cookieName === arr[0]) {
                    return arr[1];
                }
            }
            return "";
        }
        window.open(`${window.configPath.baseUrl}/filem/download?id=${id}&access-token=${getCookie('ERPToken')}`)
    },

    /**
     * @描述：获取文件列表
     * @参数:isTemplate:Boolean（是否为模板）
     * @参数:resourceType:String（附件类型）
     * @参数:mergeObj:其他需要合并的字段
     * @返回: 文件列表
     */
    getFileList(resourceType, isTemplate = true, mergeObj = {}) {
        return new Promise((resolve, reject) => {
            commonAPI
                .getFileList(Object.assign({}, {
                    isTemplate: isTemplate,
                    resourceType: resourceType
                }, mergeObj))
                .then((res) => {
                    resolve(res.content)
                })
                .catch(() => {
                    reject()
                });
        })
    },

    /**
     * @描述：显示文件（图片）
     * @参数: str:文件id 或者 https的文件放置地址
     * @参数: showUserDefault:内容为空时  默认展示用户默认图片
     * @返回: 文件
     */
    showFileImg(str) {
        if (!str) { return '' }//默认头像
        return str.indexOf("http") > -1 ? str : `${window.configPath.baseUrl}/filem/view?id=${str}`;
    },

    /**
     * @描述：文件下载转换 设置token
     * @参数:data Object{id：id,name：name} 需要下载的参数
     * @返回:
     */
    fileDownLoad(data, action = "") {
        let xhr = new XMLHttpRequest();
        xhr.open(
            "get",
            action
                ? `${window.configPath.baseUrl}${action}`
                : `${window.configPath.baseUrl}/filem/download?id=${data.id}`,
            true
        );
        xhr.setRequestHeader("access-token", this.getCookie("ERPToken")); // 添加token到请求头
        xhr.responseType = "blob"; // 返回类型blob  blob 存储着大量的二进制数据
        return new Promise((resolve) => {
            xhr.onload = function () {
                if (this.status === 200) {
                    let blob = this.response;
                    let reader = new FileReader();
                    reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
                    reader.onload = function (e) {
                        let a = document.createElement("a"); // 转换完成，创建一个a标签用于下载
                        a.download = data.name || "文件";
                        a.href = e.target.result;
                        document.body.appendChild(a); // 火狐浏览器 必须把元素插入body中
                        a.click();
                        document.body.removeChild(a);
                    };
                }
                resolve();
            };
            xhr.send(); // 发送ajax请求
        });
    },

    /**
     * @描述：文件下载转换 设置token
     * @参数:data Object{id：id,name：name} 需要下载的参数
     * @返回:
     */
    exportTableToExcel(data, action = "", fileName = "文件") {
        let xhr = new XMLHttpRequest();
        xhr.open(
            "post",
            action
                ? `${window.configPath.baseUrl}${action}`
                : `${window.configPath.baseUrl}/filem/download?id=${data}`,
            true
        );
        xhr.setRequestHeader("access-token", this.getCookie("ERPToken")); // 添加token到请求头
        xhr.setRequestHeader("Content-Type", "application/json"); // json格式
        xhr.responseType = "blob"; // 返回类型blob  blob 存储着大量的二进制数据
        return new Promise((resolve) => {
            xhr.onload = function () {
                if (this.status === 200) {
                    let blob = this.response;
                    let reader = new FileReader();
                    reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
                    reader.onload = function (e) {
                        let a = document.createElement("a"); // 转换完成，创建一个a标签用于下载
                        a.download = `${fileName}.xlsx`;
                        a.href = e.target.result;
                        document.body.appendChild(a); // 火狐浏览器 必须把元素插入body中
                        a.click();
                        document.body.removeChild(a);
                    };
                } else if (this.status === 601) {
                    Message({ message: "无数据,无法导出！", type: "error", })
                } else {
                    Message({ message: "网络错误，请稍后重试。", type: "error", })
                }
                resolve();
            };
            xhr.send(JSON.stringify(data)); // 发送ajax请求
        });
    },

    /**
     * @描述：上传指定图片（图片流）到指定服务器
     * @参数:url 图片地址
     * @参数:action 服务器地址
     * @参数:formName 上传图片的表单name
     * @参数:imgName 上传图片的名称
     * @返回:
     */
    loadImageToBlob(url, action, formName = 'file', imgName = "name.jpg") {
        // const self = this;
        return new Promise((resolve, reject) => {
            if (!url) return false;
            var xhr = new XMLHttpRequest();

            xhr.open('get', url, true);

            xhr.responseType = 'blob';

            xhr.onload = function () {
                if (!this.status === 200) { return; }
                let file = this.response;
                // 注意这里的this.response 是一个blob对象 就是文件对象

                var t = file.type;
                //这里可以根据mime决定文件名的后缀，jpg还是png。。。
                var newFile = new File([file], imgName, { type: t });
                var formData = new FormData(); // Currently empty
                formData.append(formName, newFile) // 通过append向form对象添加数据
                let config = {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }
                // 添加请求头
                axios.post(action, formData, config)
                    .then(res => {
                        resolve(res.data)
                    }).catch(() => {
                        reject()
                    })
            }
            xhr.send();
        })

    },

    /**
     * @描述：导入文件成功后的提示框已经正确错误展示
     * @参数: e：导入成功后的正确、错误数量
     * @参数: that：当前组件页面的实体this
     * @返回: Promise
     */
    importFiledSuccess(e, that) {
        return new Promise((resolve) => {
            if (e.code !== 200) { return this.$message({ message: e.message || "网络错误，请稍后重试。", type: "error", }) }
            that.$msgbox({
                title: "",
                message: this.getUploadTisTem(e.content.errorNum > 0, e, that.$createElement),
                showCancelButton: true,
                confirmButtonText:
                    e.content.errorNum > 0 ? "下载失败原因" : "确认",
                cancelButtonText: "取消",
            }).then(() => {
                //错误 需要下载
                e.content.errorNum > 0
                    ? this.fileDownLoadByid(e.content.importFileId)
                    : "";
            });
            resolve();
        })
    },

    /**
     * @描述：导入文件的大小限制 并且只能导入excel
     * @参数: file：导入的文件
     * @参数: that：当前组件页面的实体this
     * @参数: maxBit：限制文件大小最大值  默认100M
     * @返回:Boolean
     */
    limitFileSize(file, that, maxBit = 100 * 1024 * 1024) {
        // 格式由后端限制
        // if (file.name.indexOf('xls') < 0 && file.name.indexOf('xlsx') < 0) { that.$message({ message: "文件格式错误，请检查后重新上传！", type: "error" }); return false; }
        if (file.size > maxBit) { that.$message({ message: "超过文件上传上限，最大100M", type: "warning" }); return false; }
        return true;
    },

    /**
     * @描述：上传文件后的提示模板
     * @参数: e：导入成功后的正确、错误数量
     * @参数: h：实体节点
     * @参数:boolean  导入正确：true   导入有错误数据：false
     * @返回: Template
     */
    getUploadTisTem(boolean, e, h) {
        //正常模板
        let successTpl = h("p", null, [
            h(
                "i",
                {
                    class: "el-icon-success",
                    style: "color: #00B600;font-size:18px",
                },
                ""
            ),
            h(
                "span",
                { style: "color: #000;font-size:16px;margin-left:10px" },
                "导入成功 "
            ),
            h("p", { style: "margin-top:15px" }, [
                h(
                    "span",
                    { style: "color: #000;font-size:14px;" },
                    "上传成功 "
                ),
                h(
                    "span",
                    { style: "color: #00B600;font-size:14px;" },
                    e.content.rightNum + "条"
                ),
                h(
                    "span",
                    { style: "color: #000;font-size:14px;" },
                    "数据 "
                ),
            ]),
        ]);
        //错误模板
        let errorTpl = h("p", null, [
            h(
                "i",
                {
                    class: "el-icon-question",
                    style: "color: #FFBD26;font-size:20px",
                },
                ""
            ),
            h(
                "span",
                { style: "color: #000;font-size:16px;margin-left:10px" },
                "是否查看失败原因 "
            ),
            h("p", { style: "margin-top:15px" }, [
                h(
                    "span",
                    { style: "color: #000;font-size:14px;" },
                    "上传成功 "
                ),
                h(
                    "span",
                    { style: "color: #00B600;font-size:14px;" },
                    e.content.rightNum + "条 "
                ),
                h(
                    "span",
                    { style: "color: #000;font-size:14px;" },
                    "数据 "
                ),
            ]),
            h("p", { style: "margin-top:10px" }, [
                h(
                    "span",
                    { style: "color: #000;font-size:14px;" },
                    "上传失败 "
                ),
                h(
                    "span",
                    { style: "color: #FF3F2B;font-size:14px;" },
                    e.content.errorNum + "条 "
                ),
                h(
                    "span",
                    { style: "color: #000;font-size:14px;" },
                    "数据 "
                ),
            ]),
        ]);
        return boolean ? errorTpl : successTpl;
    },
};

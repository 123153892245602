/*
 * @Descripttion:
 * @Author: luoyang
 * @Date: 2021-09-22 11:05:08
 * @LastEditors: luoyang
 * @LastEditTime: 2021-09-24 15:13:48
 */
export const homeConfig = [
  // 官网配置页面
  {
    path: "/homeConfig",
    component: () => import("@/views/layout/Layout"),
    redirect: "/homeConfig",
    meta: {
      title: "官网配置",
      icon: "icon_dashboard",
    },
    children: [
      {
        path: "news",
        component: () => import("@/views/homeConfig/informationPublish/index"),
        name: "news",
        meta: {
          title: "信息发布",
        },
      },
      {
        path: "addNews",
        component: () => import("@/views/homeConfig/informationPublish/comAddNews"),
        name: "addNews",
        meta: {
          title: "新增信息",
        },
      },
      {
        path: "jobs",
        component: () => import("@/views/homeConfig/jobs/index"),
        name: "jobs",
        meta: {
          title: "招聘管理",
        },
      },
    ],
  },
];

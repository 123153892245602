import Vue from "vue";
import App from "./App.vue";
import Widealui from "wideal-elementui-web2";
import Cookies from "js-cookie";
import i18n from "./lang"; // Internationalization
import * as filters from "./filters"; // global filters
import router from "./router";
import store from "./store";
import axios from "axios";
import { Message } from "element-ui";
import "wideal-elementui-web2/lib/theme-chalk/index.css";
import "normalize.css/normalize.css"; // A modern alternative to CSS resets
import "@/styles/index.scss"; // global css
import "./icons"; // icon
import "./errorLog"; // error log
import "./permission"; // permission control
import "./directive/role/role.js"; // permission control
// 引入图表库
import "../public/font/iconfont.css"; // A modern alternative to CSS resets
// 引入echarts
import echarts from "echarts";
Vue.prototype.$echarts = echarts;

import aMap from "vue-amap"; // 问这个引入的最好放在引入的vue上面
Vue.use(aMap);
aMap.initAMapApiLoader({
    key: "5e034b390f8af685a3a554333095452a", // 你的key
    plugin: [
        "AMap.Autocomplete",
        "AMap.PlaceSearch",
        "AMap.Scale",
        "AMap.OverView",
        "AMap.ToolBar",
        "AMap.MapType",
        "AMap.Geolocation",
        "AMap.Geocoder",
        "AMap.AMapManager",
        "AMap.Marker",
    ], //应用功能项
    v: "1.4.4", //版本
    uiVersion: "1.0", //ui版本
});

//全局配置
Vue.config.silent = true //取消日志和警告

// 公共js
import commonJs from "@/utils/common.js";
Vue.prototype.commonJs = commonJs;

import moment from "moment"; //导入文件
Vue.prototype.$moment = moment;//赋值使用

Vue.prototype.$baseurl = window.configPath.baseUrl;
Vue.prototype.$message = Message;

Vue.prototype.$axios = axios // 此处命名为rq,你可以改

Vue.use(Widealui, {
    size: Cookies.get("size") || "medium", // set element-ui default size
    i18n: (key, value) => i18n.t(key, value),
});

Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");

// 设备管理

export const deviceManageRouter = [
    // 设备管理
    {
        path: "/deviceManage",
        component: () => import("@/views/layout/Layout"),
        redirect: "/deviceManage",
        meta: {
            title: "设备管理",
            icon: "icon_deviceManage",
        },
        children: [
            {
                path: "index",
                component: () => import("@/views/deviceManage/index"),
                name: "deviceManage",
                meta: {
                    title: "设备管理",
                    keepAlive: false, // 需要被缓存
                },
            },
            {
                path: "safeguardManage",
                component: () =>
                    import("@/views/safeguardManage/safeguardManage"),
                name: "safeguardManage",
                meta: {
                    title: "维护管理",
                    keepAlive: false, // 需要被缓存
                },
            },
            {
                path: "safeguardRecord",
                component: () =>
                    import("@/views/safeguardManage/safeguardRecord"),
                name: "safeguardRecord",
                meta: {
                    title: "维护记录",
                    keepAlive: false, // 需要被缓存
                },
            },
            {
                path: "/deviceManage/safeguardDetails",
                component: () =>
                    import("@/views/safeguardManage/safeguardDetails"),
                name: "safeguardDetails",
                hidden: true,
                meta: {
                    title: "维护任务详情",
                },
            },
            {
                path: "/deviceManage/safeguardRecordDetails",
                component: () =>
                    import("@/views/safeguardManage/safeguardRecordDetails"),
                name: "safeguardRecordDetails",
                hidden: true,
                meta: {
                    title: "维护记录详情",
                },
            },
            {
                path: "/deviceManage/taskDetails",
                component: () => import("@/views/safeguardManage/taskDetails"),
                name: "taskDetails",
                hidden: true,
                meta: {
                    title: "任务详情",
                },
            },
        ],
    },
    // 库存管理
    {
        path: "/stockManage",
        component: () => import("@/views/layout/Layout"),
        redirect: "/stockManage",
        meta: {
            title: "库存管理",
            icon: "icon_stockManage",
        },
        children: [
            {
                path: "index",
                component: () => import("@/views/stockManage/index"),
                name: "stockManage",
                meta: {
                    title: "库存管理",
                    keepAlive: false, // 需要被缓存
                },
            },
            {
                path: "inoutManage",
                component: () => import("@/views/stockManage/inoutManage"),
                name: "inoutManage",
                meta: {
                    title: "出入库管理",
                    keepAlive: false, // 需要被缓存
                },
            },
            {
                path: "loseManage",
                component: () =>
                    import("@/views/stockManage/loseManage"),
                name: "loseManage",
                meta: {
                    title: "遗失管理",
                    keepAlive: false, // 需要被缓存
                },
            },
            // {
            //     path: "bindDevice",
            //     component: () => import("@/views/stockManage/bindDevice"),
            //     name: "bindDevice",
            //     meta: {
            //         title: "设备绑定",
            //     },
            // },
            {
                path: "deviceInfoList",
                component: () => import("@/views/stockManage/deviceInfoList"),
                hidden: true,
                name: "deviceInfoList",
                meta: {
                    title: "设备信息",
                },
            },
            {
                path: "noCodeDeviceDetails",
                component: () => import("@/views/stockManage/noCodeDeviceDetails"),
                hidden: true,
                name: "noCodeDeviceDetails",
                meta: {
                    title: "非赋码设备详情",
                },
            },
            // {
            //     path: "deviceDetails",
            //     component: () => import("@/views/stockManage/deviceInoutRecord"),
            //     hidden: true,
            //     name: "deviceDetails",
            //     meta: {
            //         title: "设备详情",
            //     },
            // },
            {
                path: "deviceInoutRecord",
                component: () =>
                    import("@/views/stockManage/deviceInoutRecord"),
                hidden: true,
                name: "deviceInoutRecord",
                meta: {
                    title: "设备详情",
                },
            },
            {
                path: "inoutDetails",
                component: () => import("@/views/stockManage/inoutDetails"),
                hidden: true,
                name: "inoutDetails",
                meta: {
                    title: "出入库详情",
                },
            },
        ],
    },
    // 维护管理
    // {
    //     path: "/safeguardManage",
    //     component: () => import("@/views/layout/Layout"),
    //     redirect: "/safeguardManage",
    //     meta: {
    //         title: "维护管理",
    //         icon: "icon_safeguardManage",
    //     },
    //     children: [
    //         {
    //             path: "taskDetails",
    //             component: () => import("@/views/safeguardManage/taskDetails"),
    //             name: "taskDetails",
    //             meta: {
    //                 title: "部署详情",
    //             },
    //         },
    //     ],
    // },
    // 检测管理
    {
        path: "/testManage",
        component: () => import("@/views/layout/Layout"),
        redirect: "/supplierInfoManage",
        meta: {
            title: "检测管理",
            icon: "icon_testManage",
        },
        children: [
            {
                path: "/testManage/hardwareDetectionManage",
                component: () => import("@/views/testManage/hardwareDetectionManage"),
                name: "hardwareDetectionManage",
                hidden: true,
                meta: {
                    title: "硬件检测项管理",
                },
            },
            {
                path: "/testManage/hardwarePowerManage",
                component: () => import("@/views/testManage/hardwarePowerManage"),
                name: "hardwarePowerManage",
                hidden: true,
                meta: {
                    title: "硬件能力管理",
                },
            },
            {
                path: "/testManage/testRecord",
                component: () => import("@/views/testManage/testRecord"),
                name: "testRecord",
                hidden: true,
                meta: {
                    title: "检测记录",
                },
            }
        ]
    },
    // 基础信息管理
    {
        path: "/baseInfoManage",
        component: () => import("@/views/layout/Layout"),
        redirect: "/supplierInfoManage",
        meta: {
            title: "基础信息管理",
            icon: "icon_baseInfoManage",
        },
        children: [
            {
                path: "supplierInfoManages",
                component: () =>
                    import("@/views/baseInfoManage/supplierInfoManages"),
                name: "supplierInfoManages",
                meta: {
                    title: "供应商信息管理",
                },
            },

            {
                path: "supplierDetail",
                component: () =>
                    import("@/views/baseInfoManage/supplierDetail"),
                name: "supplierDetail",
                hidden: true,
                meta: {
                    title: "供应商信息详情",
                },
            },
            {
                path: "supplierRecordDetail",
                component: () =>
                    import("@/views/baseInfoManage/supplierRecordDetail"),
                name: "supplierRecordDetail",
                hidden: true,
                meta: {
                    title: "采购记录详情",
                },
            },
            {
                path: "softwareInfoManage",
                component: () =>
                    import("@/views/baseInfoManage/softwareInfoManage"),
                name: "softwareInfoManage",
                meta: {
                    title: "软件信息管理",
                },
            },
            {
                path: "systemDetail",
                component: () => import("@/views/baseInfoManage/systemDetail"),
                name: "softwareInfoManage",
                hidden: true,
                meta: {
                    title: "软件版本管理",
                },
            },
            {
                path: "comSoftwareDetail",
                component: () =>
                    import(
                        "@/views/baseInfoManage/components/softwareVersion/comSoftwareDetail"
                    ),
                name: "comSoftwareDetail",
                hidden: true,
                meta: {
                    title: "软件版本详情",
                },
            },
            {
                path: "deviceTypeManage",
                component: () =>
                    import("@/views/baseInfoManage/deviceTypeManage"),
                name: "deviceTypeManage",
                meta: {
                    title: "设备类型管理",
                },
            },
            {
                path: "deviceModelManage",
                component: () =>
                    import("@/views/baseInfoManage/deviceModelManage"),
                name: "deviceModelManage",
                hidden: true,
                meta: {
                    title: "设备型号管理",
                },
            },
            {
                path: "deviceModelDetail",
                component: () =>
                    import("@/views/baseInfoManage/deviceModelDetail"),
                name: "deviceModelDetail",
                hidden: true,
                meta: {
                    title: "设备型号详情",
                },
            },
            {
                path: "customerInfoManage",
                component: () =>
                    import("@/views/baseInfoManage/customerInfoManage"),
                name: "customerInfoManage",
                meta: {
                    title: "客户信息管理",
                },
            },
            {
                path: "bomManage",
                component: () =>
                    import("@/views/baseInfoManage/bomManage"),
                name: "bomManage",
                meta: {
                    title: "物料清单管理",
                },
            },
        ],
    },
];

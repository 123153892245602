export default {
    route: {
        dashboard: "首页",
        introduction: "简述",
        documentation: "文档",
        guide: "引导页",
        errorPages: "错误页面",
        page401: "401",
        page404: "404",
        errorLog: "错误日志",
        permission: "权限测试页",
        pagePermission: "页面权限",
        directivePermission: "指令权限",
    },
    login: {
        title: "系统登录",
        logIn: "登录",
        username: "账号/手机号",
        password: "密码",
    },
    navbar: {
        logOut: "退出登录",
        dashboard: "首页",
        github: "项目地址",
        theme: "换肤",
        size: "布局大小",
    },
    // guide: {
    //   description: '引导页对于一些第一次进入项目的人很有用，你可以简单介绍下项目的功能。本 Demo 是基于',
    //   button: '打开引导'
    // },
    // tagsView: {
    //   refresh: '刷新',
    //   close: '关闭',
    //   closeOthers: '关闭其它',
    //   closeAll: '关闭所有'
    // },
    // permission: {
    //   roles: '你的权限',
    //   switchRoles: '切换权限',
    //   tips: '在某些情况下，不适合使用 v-permission。例如：Element-UI 的 Tab 组件或 el-table-column 以及其它动态渲染 dom 的场景。你只能通过手动设置 v-if 来实现。'
    // }
};

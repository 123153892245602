const getters = {
    sidebar: (state) => state.app.sidebar,
    language: (state) => state.app.language,
    size: (state) => state.app.size,
    device: (state) => state.app.device,
    avatar: (state) => state.user.avatar,
    userName: (state) => state.user.userName,
    userInfo: (state) => state.user.userInfo,
    introduction: (state) => state.user.introduction,
    roles: (state) => state.user.roles,
    taskDetailsMenuId: (state) => state.role.taskDetailsMenuId,
    taskDetailsRoleInfo: (state) => state.role.taskDetailsRoleInfo,
    deviceDetailsMenuId: (state) => state.role.deviceDetailsMenuId,
    deviceDetailsRoleInfo: (state) => state.role.deviceDetailsRoleInfo,
    addRouters: (state) => state.permission.addRouters,
    visitedViews: (state) => state.tagsView.visitedViews,
    cachedViews: (state) => state.tagsView.cachedViews,
    errorLogs: (state) => state.errorLog.logs,
    userPosition: (state) => state.app.userPosition,
    routeList: (state) => state.role.routeList,
    reserveProjectBaseDetails: (state) => state.reserveProject.detailsInfo,
    needAuditCount: (state) => state.user.needAuditCount,
    applyType: (state) => state.apply.applyType,
    accountType: (state) => state.finance.accountType, // 账户类型
    accountSubjectTypeOpts: (state) => state.finance.accountSubjectTypeOpts, // 账户对应会计科目
    salaryPwd: (state) => state.user.salaryPwd, // 薪资模块密码
};
export default getters;

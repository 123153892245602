// import Vue from 'vue'
import CryptoJS from "crypto-js";

export default {
    // 加密
    encrypt(word, keyStr) {
        if (!word) {
            return "";
        }
        keyStr = keyStr ? keyStr : "abcdefgabcdefg12";
        // keyStr是aes加密需要用到的16位字符串的key
        // word是待加密或者解密的字符串
        var key = CryptoJS.enc.Utf8.parse(keyStr);
        var srcs = CryptoJS.enc.Utf8.parse(word);
        var encrypted = CryptoJS.AES.encrypt(srcs, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString();
    },
    // 解密
    decrypt(word, keyStr) {
        if (!word) {
            return "";
        }
        keyStr = keyStr ? keyStr : "abcdefgabcdefg12";
        var key = CryptoJS.enc.Utf8.parse(keyStr); // Latin1 w8m31+Yy/Nw6thPsMpO5fg==
        var decrypt = CryptoJS.AES.decrypt(word, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    },

    /**
     * @描述：数字字符串 去负号
     * @参数: num： String
     * @返回: String0
     */
    wageAbs(num) {
        return num ? num.toString().replace("-","") : num;
    },
};

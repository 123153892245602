// import { loginByUsername, logout, getUserInfo } from '@/api/login'
import httpServer from "@/api/login";
import commonAPI from "@/api/commonAPI";
import commonJs from "@/utils/common.js";
import humanResourcesManageApi from "@/api/humanResourcesManageApi.js";
import { Message } from "element-ui";
import {
    getPublicKey,
    getToken,
    removePublicKey,
    removeToken,
    removeUserName,
    removeUserNameId,
    setPublicKey,
    setToken,
    setUserName,
    setUserNameId,
} from "@/utils/auth";

const user = {
    state: {
        user: "",
        userName: "",
        status: "",
        code: "",
        publicKey: getPublicKey(),
        token: getToken(),
        name: "",
        avatar: "",
        introduction: "",
        roles: [],
        setting: {
            articlePlatform: [],
        },
        userInfo: null, //当前登录用户基本信息
        salaryKey: {}, //缓存当前查看薪资情况的公钥、私钥   Object {}
        seeSalaryPwd: null, //缓存当前查看薪资情况的正确密码
        needAuditCount: 0, // 待审批数量
        salaryPwd: null, // 薪资模块密码
    },

    mutations: {
        SET_CODE: (state, code) => {
            state.code = code;
        },
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_PUBLICKEY: (state, publicKey) => {
            state.publicKey = publicKey;
        },
        SET_INTRODUCTION: (state, introduction) => {
            state.introduction = introduction;
        },
        // SET_SETTING: (state, setting) => {
        //   state.setting = setting
        // },
        // SET_STATUS: (state, status) => {
        //   state.status = status
        // },
        SET_USERNAME: (state, userName) => {
            state.userName = userName;
        },
        SET_USERID: (state, userId) => {
            state.userId = userId;
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar;
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles;
        },
        SET_USERINFO: (state, info) => {
            state.userInfo = info;
        },
        SET_SALARTKEY: (state, obj) => {
            state.salaryKey = obj;
        },
        SET_SEESALARYPWD: (state, pwd) => {
            pwd && (state.seeSalaryPwd = pwd);
        },
        SET_SALARYPWD: (state, pwd) => {
            pwd && (state.salaryPwd = pwd);
        },
        // setSeeSalaryPwd
        SET_NEEDAUDITCOUNT: (state, needAuditCount) => {
            state.needAuditCount = needAuditCount;
        },
    },

    actions: {
        // 获取publickey
        GetPublicKey({ commit }) {
            return new Promise((resolve, reject) => {
                httpServer
                    .getPublicKey()
                    .then((res) => {
                        commit("SET_PUBLICKEY", res.content);
                        setPublicKey(res.content);
                        resolve(res);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // 用户名登录
        LoginByUsername({ commit, state }, userInfo) {
            return new Promise((resolve, reject) => {
                httpServer
                    .loginByUsername({
                        publicKey: state.publicKey,
                        uaccount: userInfo.uaccount,
                        upwd: userInfo.upwd,
                    })
                    .then((res) => {
                        commit("SET_TOKEN", res.content);
                        setToken(res.content);
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        // 获取用户信息
        GetUserInfo({ commit, state }) {
            return new Promise((resolve, reject) => {
                if (state.token) {
                    httpServer
                        .getUserInfo({ accessToken: state.token })
                        .then((res) => {
                            const data = res.content;
                            console.log(data);
                            commit("SET_USERNAME", data.userName);
                            commit("SET_USERID", data.id);
                            setUserName(data.userName);
                            setUserNameId(data.id);
                            resolve(res);
                        })
                        .catch((error) => {
                            Message({
                                message: "获取用户信息失败，请稍后再试！",
                                type: "error",
                            });
                            reject(error);
                        });
                } else {
                    Message({
                        message: "获取用户信息失败，请稍后再试！",
                        type: "error",
                    });
                    reject();
                }
            });
        },

        // 登出
        LogOut({ state }) {
            return new Promise((resolve, reject) => {
                httpServer
                    .logout({ accessToken: state.token })
                    .then(() => {
                        this.dispatch("LogOutDone");
                        location.href = `${location.origin}/#/login`;
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        //获取当前登录人的信息
        // @parm $forceUpdate:是否需要强制更新用户信息
        getCurrentLoginUserInfo({ commit, state }, forceUpdate = false) {
            return new Promise((resolve, reject) => {
                return state.userInfo && !forceUpdate
                    ? resolve(state.userInfo)
                    : commonAPI
                        .findCurrentUser()
                        .then((res) => {
                            commit("SET_USERINFO", res.content);
                            resolve(res.content);
                        })
                        .catch((error) => {
                            reject(error);
                        });
            });
        },

        LogOutDone({ commit }) {
            console.log("LogOutDone");
            commit("SET_TOKEN", "");
            commit("SET_PUBLICKEY", "");
            commit("SET_USERINFO", "");
            removeToken();
            removePublicKey();
            removeUserName();
            removeUserNameId();
        },

        //同步自己钉钉电话
        syncDingTalkTel() {
            return new Promise((resolve, reject) => {
                humanResourcesManageApi
                    .syncDingTalkTel()
                    .then((v) => {
                        resolve(v);
                        Message({ message: "同步成功", type: "success" });
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        //解除自己的微信绑定
        unbindWeiXin() {
            return new Promise((resolve, reject) => {
                humanResourcesManageApi
                    .unbindWeiXin()
                    .then((v) => {
                        resolve(v);
                        Message({
                            message: "解除微信绑定成功",
                            type: "success",
                        });
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        //获取当前薪酬加解密公钥私钥
        getSalaryKey({ commit, state }){
            return new Promise((resolve) => {
                if(state.salaryKey && JSON.stringify(state.salaryKey) !== "{}"){
                    resolve(state.salaryKey);
                } else {
                    commonJs.getRsaKeys().then((opt)=>{
                        // let privateKey = opt.privateKey,publicKey = opt.publicKey
                        commit("SET_SALARTKEY", {
                            ...opt
                        });
                        resolve({
                            ...opt
                        });
                    })
                }
            });
        }

        // // 动态修改权限
        // ChangeRoles({ commit, dispatch }, role) {
        //     return new Promise(resolve => {
        //         commit('SET_TOKEN', role)
        //         setToken(role)
        //         getUserInfo(role).then(res => {
        //             const data = res.data
        //             commit('SET_ROLES', data.roles)
        //             commit('SET_USERNAME', data.name)
        //             commit('SET_AVATAR', data.avatar)
        //             commit('SET_INTRODUCTION', data.introduction)
        //             dispatch('GenerateRoutes', data) // 动态修改权限后 重绘侧边菜单
        //             resolve()
        //         })
        //     })
        // }
    },
};

export default user;

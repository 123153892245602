export default {
    /**
     * @描述：判断是否为图片
     * @参数: fileName： String
     * @返回: Boolean
     */
    filterIsImg(fileName) {
        //判断是否是图片 - strFilter必须是小写列举
        let strFilter = ".jpeg|.gif|.jpg|.png|.bmp|.pic|.svg|";
        if (fileName.indexOf(".") > -1) {
            var p = fileName.lastIndexOf(".");
            var strPostfix = fileName.substring(p, fileName.length) + "|";
            strPostfix = strPostfix.toLowerCase();
            if (strFilter.indexOf(strPostfix) > -1) {
                return true;
            }
        }
        return false;
    },
    /**
     * @描述：判断是否为Pdf
     * @参数: fileName： String
     * @返回: Boolean
     */
    filterIsPdf(fileName) {
        //判断是否是Pdf - strFilter必须是小写列举
        let strFilter = ".pdf|.PDF";
        if (fileName.indexOf(".") > -1) {
            var p = fileName.lastIndexOf(".");
            var strPostfix = fileName.substring(p, fileName.length) + "|";
            strPostfix = strPostfix.toLowerCase();
            if (strFilter.indexOf(strPostfix) > -1) {
                return true;
            }
        }
        return false;
    },
    /**
     * @描述：文件管理模块是否有文件查询
     * @参数: title: String(如：项目核算)；countList: Array(/filem/getFileByModule/{module}接口返回的)
     * @返回: Boolean: true-有文件 | false-没文件
     */
    hadFile(title, countList) {
        let filterName = countList.filter((item) => {
            return item.name === title;
        });
        if (filterName.length === 1) {
            return filterName[0].files.length > 0;
        } else {
            return false;
        }
    },
};

export default {
    /**
     * 四舍五入保留两位小数，若第二位是0则忽略
     * @param {*} num
     * step 保留多少位小数 默认两位传100 4位小数传1000
     */
    keepTwoDecimal(num, step = 100) {
        var result = parseFloat(num);
        if (isNaN(result)) {
            return 0.00;
        }
        result = Math.round(num * step) / step;
        return result;
    },
    /**
     * 只能输入两位小数数字
     */
    inputMoney(num) {
        console.log(num);
        let money = 0.00;
        money = num.match(/^\d*(\.?\d{0,2})/g)[0] || 0.00
        return money
    },
    /**
     * 金额千位符展示
     */
    toThousands(num) {
        const regex = /\B(?=(\d{3})+(?!\d))/g;
        // 将数字转换为字符串并添加千分符
        return num.toString().replace(regex, ',');
    },
};

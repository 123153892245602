// import { loginByUsername, logout, getUserInfo } from '@/api/login'

const finance = {
    // namespaced: true, // 增加命名空间
    state: {
        // 账户类型
        accountType: [
            { code: "CASH", label: "现金" },
            { code: "BANK", label: "银行" },
        ],
        // 账户对应会计科目
        accountSubjectTypeOpts: [{
            code: "CASH_ON_HAND",
            label: "库存现金"
        },
        {
            code: "BANK_DEPOSIT",
            label: "银行存款"
        },
        {
            code: "OTHER_MONETARY_FUNDS",
            label: "其他货币资金"
        },
        {
            code: "TRADING_FINANCIAL_ASSETS",
            label: "交易性金融资产"
        },
        {
            code: "NOTES_RECEIVABLE",
            label: "应收票据"
        },
        {
            code: "SALEABLE_FINANCIAL_ASSETS",
            label: "可供出售金融资产"
        }]
    },

    mutations: {
        // setProjectStatus: (state, obj) => {
        //     state.projectStatus = obj;
        // },
    },

    actions: {},
};

export default finance;

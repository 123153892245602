export const erp = [
    // 人资管理
    {
        path: "/humanResourcesManage",
        component: () => import("@/views/layout/Layout"),
        redirect: "/humanResourcesManage",
        meta: {
            title: "人资信息管理",
            icon: "icon_deviceManage",
        },
        children: [
            {
                path: "onJobList",
                component: () => import("@/views/humanResourcesManage/staffManagement/onjob/index"),
                name: "onJobList",
                meta: {
                    title: "在职员工花名册",
                },
            },
            {
                path: "departureList",
                component: () => import("@/views/humanResourcesManage/staffManagement/departure/index"),
                name: "departureList",
                meta: {
                    title: "离职员工花名册",
                },
            },
            {
                path: "detailContainer",
                component: () => import("@/views/humanResourcesManage/staffManagement/components/detailContainer"),
                name: "detailContainer",
                meta: {
                    title: "详情",
                },
            },
            {
                path: "compensationFile",
                component: () => import("@/views/humanResourcesManage/compensationManagement/compensation/index"),
                name: "compensationFile",
                meta: {
                    title: "薪酬档案",
                    keepAlive: false, // 需要被缓存
                },
            },
            {
                path: "compensationDetail",
                component: () => import("@/views/humanResourcesManage/compensationManagement/compensation/detail"),
                name: "compensationDetail",
                meta: {
                    title: "薪酬详情",
                },
            },
            {
                path: "socialSecurityInfo",
                component: () => import("@/views/humanResourcesManage/socialSecurityInfo/socialSecurityInfo"),
                name: "socialSecurityInfo",
                meta: {
                    title: "社保信息配置",
                    keepAlive: false, // 需要被缓存
                },
            },
        ],
    },
];

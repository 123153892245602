// import request from '@/utils/request'
import {
    get,
    postForm,
    // post,
    postFile,
    postJson,
} from "./http/http";

const httpServer = {
    // 新增项目id获取
    getId() {
        return postJson("/project/project/addProjectId");
    },
    // 查询所属系统列表
    getSystemInfoList(params) {
        return get("/baseinfo/system-info/getSelectList", params);
    },
    // 获取设备类型下拉
    getDeviceTypeOpts(params) {
        return get("/emp/type-manager/getSelectList", params);
    },
    // 根据设备类型获取供应商下拉
    getProviderIdOpts(params) {
        return get("/emp/type-model/getProviderSelectList", params);
    },
    // 获取设备列表
    getDeviceList(params) {
        // return get(`/emp/type-field/findListByTypeId/${params || "id"}`);
        return get(`/emp/type-field/findByTypeId/${params || "id"}`);
    },
    // 获取设备列表 新建任务-选择设备
    getDeviceOpts(params) {
        return get(`/emp/type-model/findListByTypeMangerId`, params);
    },
    // 设备管理总列表
    getDeviceManageAllList(params) {
        return postJson(`/emp/device-info/arrange`, params);
    },
    // 设备管理模块列表
    getDeviceManageList(params) {
        return postJson(`/emp/device-info/tableDeviceManager`, params);
    },
    // 设备遗失管理模块列表
    getDeviceLostManageList(params) {
        return postJson(`/emp/device-info/tableLossManagement`, params);
    },
    // 设备详情列表
    getDeviceListFromId(params) {
        return postJson(`/emp/device-info/table`, params);
    },
    // 获取处理人员列表
    getPersonList(params) {
        return get("/base/user/getSelectList", params);
    },
    // 获取 项目客户树形 下拉
    // 获取 客户名称树形 下拉
    getCustomerSelectTree(params) {
        return get("/baseinfo/company/tree", params);
    },
    // 查询任务联系人
    getLinkPersonList(params) {
        return get(
            "/baseinfo/customer-company-link-info/getSelectList",
            params
        );
    },
    // 使用软件 下拉列表
    getSystemList(params) {
        return get("/baseinfo/system-info/getSelectList", params);
    },
    // 项目名称 下拉列表
    getItemSelect(params) {
        return get("/project/project/getSelectList", params);
    },
    // 项目名称 下拉 查询未完成
    getItemSelectWithStatus(params) {
        return postJson("/project/project/getSelectListWithAction", params);
    },
    // 软件版本 下拉列表
    getSystemVersionList(params) {
        return get("/baseinfo/system-version-info/getSelectList", params);
    },
    // 维护管理 列表
    listSafeguardManage(params) {
        return postJson("/maintain/maintain/table", params);
    },
    // 上传文件
    uploadFile(params) {
        return postJson("/file/upload", params);
    },
    // 上传文件 新
    uploadFileNew(params) {
        return postFile("/filem/upload", params);
    },
    // 下载文件
    downloadFile(params) {
        return get("/file/download", params);
    },
    // 查看文件
    viewFile(params) {
        return get("/file/view", params);
    },
    // 生成指定数量二维码
    downloadQrCodeForCount(params) {
        return get(`/emp/purchase-order/downloadQrCode/${params}`);
    },
    // 根据id导出二维码
    downloadQrCodeForId(id) {
        return get(`/emp/device-info/exportQrCode/${id}`);
    },
    //通过设备id查找设备详情
    findDeviceDetail(id) {
        return postJson(`/emp/type-model/findById/${id}`);
    },
    //通过设备id查找设备详情
    findNoCodeDeviceDetail(params) {
        return get(`/emp/device-info/noHaveCode-info/null`, params);
    },
    //通过设备id查找设备详情
    findDeviceInfo(id) {
        return get(`/emp/device-info/findById/${id}`);
    },
    //根据采购单ID查询相应采购单详情
    findOrderDetail(id) {
        return get(`/emp/purchase-order/findById/${id}`);
    },
    //根据采购单ID查询采购单列表
    findOrderListByOrderId(params) {
        return postJson(`/emp/purchase-view/table`, params);
    },
    //根据采购单ID查询采购单列表  进行供应商分组
    findOrderListByOrderId_(params) {
        return get(`/emp/purchase-view/tableProviderByOrderId`, params);
    },
    //根据采购单ID查询设备列表
    findDeviceListByOrderId(params) {
        return postJson(`/emp/device-info/table`, params);
    },
    //生成唯一主键  关联附件
    getUniqueId() {
        return get(`/base/common/getId`);
    },

    //获取文件列表
    getFileList(params) {
        return get(`/filem/list`, params);
    },
    //获取实施管理文件列表
    getFileListForIm(params) {
        return get(`/filem/recommend-task-file`, params);
    },
    //获取项目管理文件列表
    getFileListForPro(params) {
        return get(`/filem/recommend-project-file`, params);
    },
    //获取文件列表
    getFileCount(module, resourceId) {
        return get(`/filem/getFileByModule/${module}`, {
            resourceId: resourceId,
        });
    },
    //获取推荐文件列表
    getRecommendFileList(params) {
        return get(`/filem/recommend-file`, params);
    },

    //删除对应附件
    deleteFileById(params) {
        return get(`/filem/delete`, params);
    },

    //下载对应附件
    downloadFileById(id) {
        return get(`/filem/download`, id);
    },

    //获取所有人员数据
    getAllUserList(params) {
        return get(`/base/user/getSelectList`, params);
    },

    //获取组织结构下拉数据
    getOrgList() {
        return get(`/base/department/getSelectTree`);
    },

    //获取角色下拉数据
    getRoleList(params) {
        return get(`/base/role/getSelectList`, params);
    },

    // 根据字典模块名称获取所有字典项
    getModuleDict(module) {
        return get(`/dict/item/getDictItemByModule/${module}`);
    },

    // 根据字典code获取字典项
    getModuleCode(code) {
        return get(`/dict/item/byCode/${code}`);
    },

    // 新建字典
    saveDict(params) {
        return postJson("/dict/dict/saveDic", params);
    },
    // 编辑字典
    editDict(params) {
        return postJson("/dict/dict/editDic", params);
    },
    // 新建 | 编辑字典项
    saveDictItem(params) {
        return postJson("/dict/item/saveItem", params);
    },
    // 删除字典
    delDict(id) {
        return get(`/dict/dict/delete/${id}`);
    },
    // 删除字典项
    delDictItem(id) {
        return get(`/dict/item/delete/${id}`);
    },

    // 查询字典列表
    getDictList(params) {
        return postJson("/dict/dict/table", params);
    },

    // 检测状态枚举
    getCheckStatus() {
        return get(`/emp/device-info/getCheckStatus/DeviceCheckStatus`);
    },


    // 获取项目进度
    getAllProjectType() {
        return get(`/project/project/findAllProjectType`);
    },
    // 查询合同列表
    getContractTable(params) {
        return postJson("/contract/contract/table", params);
    },
    // 新建企业人员 干系人
    savePerson(params) {
        return postJson("/baseinfo/company-link-info/save", params);
    },
    // 添加项目干系人
    linkPersonAndProject(params) {
        return postJson(
            "/reserveproject/project-customer-link-relevance/save",
            params
        );
    },
    // 添加项目干系人-ERP3.1
    saveLinkPerson(params) {
        return postJson(
            "/reserveproject/project-customer-link-relevance/saveByReserveProject",
            params
        );
    },
    // 删除项目干系人
    delLinkPerson(id) {
        return get(
            `/reserveproject/project-customer-link-relevance/delete/${id}`
        );
    },
    // 废止实施计划 项目
    abolishTask(params) {
        return get("/task/task/abolish", params);
    },
    // 查询涉及系统
    getSystemToItem(id) {
        return get(
            `/reserveproject/reserve-project-system-relevance/find/${id}`
        );
    },
    // 添加涉及系统
    addSystemToItem(params) {
        return postJson(
            "/reserveproject/reserve-project-system-relevance/save",
            params
        );
    },
    // 删除涉及系统
    delSystemToItem(id) {
        return get(
            `/reserveproject/reserve-project-system-relevance/delete/${id}`
        );
    },
    // 获取项目下的人员列表
    itemPersonList(params) {
        return postJson(
            "/project/project-user-relevance/findByProjectId",
            params
        );
    },
    // 保存成员
    savePersonList(params) {
        return postJson("/project/project-user-relevance/save", params);
    },
    // 查询关联进行中的项目
    getSystemLinkItems(params) {
        return get("/project/project/projectByReserveId", params);
    },
    // 查询企业人员信息
    getPersonInfo(id) {
        return get(`/baseinfo/company-link-info/info/${id}`);
    },
    // 获取施工日志列表
    getTaskLog(params) {
        return postJson("/task/construction-log/table", params);
    },

    // 修改密码
    resetPwd(params) {
        return postJson("/base/user/updatePwd", params);
    },

    // 根据code获取后台枚举下拉值--v3.0.0
    getSelectByCode(code) {
        return get(`/base/common/byCode/${code}`);
    },

    // 关联项目搜索
    linkItemSearch(params) {
        return postForm(`/project/project/projectSearch`, {
            projectName: params,
        });
    },
    // 任务类型下拉第一级
    getTaskTypeOpts(code) {
        return get(`/dict/dict/getDictByModule/${code}`);
    },
    // 任务类型下拉第二级
    getTaskTypeNode(code) {
        return get(`/dict/item/byDict/${code}`);
    },
    //查询当前登录用户信息-v3.0.0
    findCurrentUser() {
        return get(`/base/user/findCurrentUser`);
    },
    // 切换任务状态
    changeTaskStatus(id, status) {
        return postJson(`/workTask/work-task/changeStatus/${id}/${status}`);
    },
    // 休假信息同步 钉钉
    synchroVacation() {
        return postJson(`/user/leave-status/syncDingTalkLeaveStatus`);
    },
    // 获取负责人下拉
    getPersonOpts(params) {
        return get(
            `/reserveproject/project-customer-link-relevance/findManagerList`,
            { type: params }
        );
    },
    // 获取规格型号下拉
    getTypeModelOpts(params) {
        return get(`/emp/type-model/getTypeModelSelectList`, {
            typeManagerId: params,
        });
    },

    // 查询公司账户
    getCompanyAccount() {
        return get(`/contract/contract/find-company-dict`);
    },

    // 保存公司账户
    saveCompanyAccount(params) {
        return postJson(`/contract/contract/save-company-manager`, params);
    },

    /**
     * 获取收入类别下拉 type = INCOME
     * 获取支出类别下拉 type = EXPENDITURE
     * 获取税费类别下拉 type = TAX
     * 获取报销费用类别下拉 type = REIMBURSEMENT
     */
    getCategoryTypeOpts(type) {
        return get(`/category/getSelectTree/${type}`);
    },

    /**
     * 是否需要重置密码
     */
    isFistLogin() {
        return get(`/base/user/findUserNeedUpdatePwd`);
    },

    // 根据流水记录ID获取设备二维码
    getFlowListQrCode(params) {
        return get(`/emp/device-info/getQrCodeByFlowId`, params);
    },

};

export default httpServer;

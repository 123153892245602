export const baseRouter = [
    {
        path: "/redirect",
        component: () => import("@/views/layout/Layout"),
        hidden: true,
        children: [
            {
                path: "/redirect/:path*",
                component: () => import("@/views/redirect/index"),
                // component: () => import("@/views/login/index"),
            },
        ],
    },
    {
        path: "/templateManage",
        component: () => import("@/views/layout/Layout"),
        hidden: true,
        children: [
            {
                path: "deviceImg",
                component: () => import("@/views/templateManage/components/comDefaultDeviceImg"),
                // component: () => import("@/views/login/index"),
            },
        ],
    },
    {
        path: "/login",
        component: () => import("@/views/login/index"),
        hidden: true,
    },
    {
        path: "/404",
        component: () => import("@/views/errorPage/404"),
        hidden: true,
    },
    {
        path: "/401",
        component: () => import("@/views/errorPage/401"),
        hidden: true,
    },
    {
        path: "/loginForward",
        component: () => import("@/views/login/loginForward"),
        hidden: true,
    },
    {
        //微信激活页面
        path: "/wxActivate",
        component: () => import("@/views/login/wxActivate"),
        hidden: true,
    },
];

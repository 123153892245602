import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import getters from "./getters";
import errorLog from "./modules/errorLog";
import permission from "./modules/permission";
import user from "./modules/user";
import tagsView from "./modules/tagsView";
import contract from "./modules/contract";
import role from "./modules/role";
import reserveProject from "./modules/reserveProject";
import enums from "./modules/enums";
import apply from "./modules/apply";
import finance from "./modules/finance";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        app,
        user,
        permission,
        tagsView,
        errorLog,
        contract,
        role,
        reserveProject,
        enums,
        apply,
        finance
    },
    getters,
});

export default store;

import { Message } from "element-ui";
export default {
    /**
     * @描述：计算表格高度
     * @参数: options
     * defaultTopHeight:默认顶部高度
     * pageOtherHeight:当前页面容器除表格外其他容器的高度
     * @返回: 高度
     */
    getTableHeight(opt) {
        const options = Object.assign(
            {
                defaultTopHeight: 85,
            },
            opt
        );
        return (
            window.innerHeight -
            options.defaultTopHeight -
            options.pageOtherHeight
        );
    },

    /*
     * 后台校验数据提示
     * */
    dataCheckMsg: function (data) {
        data.forEach((res) => {
            for (let i in res) {
                Message({
                    message: res[i].messages || "远程请求失败",
                    type: "error",
                });
            }
        });
    },

    /**
     * @描述：JSON深拷贝转化
     * @参数:data 需要拷贝的参数
     * @返回: 拷贝后的数据
     */
    deepCopy(data) {
        return JSON.parse(JSON.stringify(data));
    },

    /**
     * @描述：阿拉伯数字转中文
     * @参数:num 数字
     * @返回:
     */
    toChinesNum(num) {
        let changeNum = [
            "零",
            "一",
            "二",
            "三",
            "四",
            "五",
            "六",
            "七",
            "八",
            "九",
        ]; //changeNum[0] = "零"
        let unit = ["", "十", "百", "千", "万"];
        num = parseInt(num);
        let getWan = (temp) => {
            let strArr = temp
                .toString()
                .split("")
                .reverse();
            let newNum = "";
            for (var i = 0; i < strArr.length; i++) {
                newNum =
                    (i === 0 && strArr[i] === 0
                        ? ""
                        : i > 0 && strArr[i] === 0 && strArr[i - 1] === 0
                            ? ""
                            : changeNum[strArr[i]] +
                            (strArr[i] === 0 ? unit[0] : unit[i])) + newNum;
            }
            return newNum;
        };
        let overWan = Math.floor(num / 10000);
        let noWan = num % 10000;
        if (noWan.toString().length < 4) noWan = "0" + noWan;
        return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
    },

    /**
     *  @描述：对比两个对象的值是否完全相等 返回值 true/false
     *  @返回: boolean
     */
    isObjectValueEqual(a = {}, b = {}) {
        var aProps = Object.getOwnPropertyNames(a || {});
        var bProps = Object.getOwnPropertyNames(b || {});
        if (aProps.length !== bProps.length) {
            return false;
        }
        for (var i = 0; i < aProps.length; i++) {
            var propName = aProps[i];

            var propA = a[propName];
            var propB = b[propName];
            // 2020-11-18更新，这里忽略了值为undefined的情况
            // 故先判断两边都有相同键名
            if (!b.hasOwnProperty(propName)) return false;
            if (typeof propA === "object") {
                if (this.isObjectValueEqual(propA, propB)) {
                    // return true     这里不能return ,后面的对象还没判断
                } else {
                    return false;
                }
            } else if (propA !== propB) {
                return false;
            }
        }
        return true;
    },

    /**
     * 对Json数组排序, 将指定位数置顶
     */
    sortJson(arr, key, value) {
        let index = 0;
        let obj = {};
        arr.forEach((item, idx) => {
            if (item[key] === value) {
                index = idx;
                obj = item;
            }
        });
        arr.splice(index, 1);
        arr.unshift(obj);
        return arr;
    },

    /**
     *  @描述：对输入文字/n字符换行  转为html
     *  @参数：description：转换字符
     *  @返回: String
     */
    conversionHtml(description) {
        return description ? description.replace(/\n/g, '<br>') : '无'
    },

    /**
     * 金额保留两位小数，不四舍五入
     */
    moneyToFixed(num) {
        return Math.floor(num * 100) / 100
    }

}
// import { loginByUsername, logout, getUserInfo } from '@/api/login'

const apply = {
    // namespaced: true, // 增加命名空间
    state: {
        // 审批类型
        applyType: {
            资金调拨: 'BANK_TREASURER',
            收入合同: 'INCOME_CONTRACT',
            其他收入: 'OTHER_INCOME',
            支出合同: 'EXPENDITURE_CONTRACT',
            其他支出: 'OTHER_EXPENDITURE',
            '交押金/保证金': 'DEPOSIT',
            员工借支: 'ADVANCES_EMPLOYEES',
            借出款: 'LEND_COMPANY',
            预付款: 'IMPREST',
            报销单: 'EXPENSE_ACCOUNT',
            开票申请: 'MAKE_INVOICE',
            工资: 'WAGE',
            缴税费: 'TAX',
            退税费: 'REFUND_BILL',
            '收押金/保证金': 'RECEIVE_DEPOSIT',
            预收款: 'ADVANCES_RECEIVED',
            借入款: 'BORROWED',
            社保: 'SOCIAL_SECURITY',
            增股: 'STOCK_IN_FLOW',
            减股: 'STOCK_OUT_FLOW'
        }
    },

    mutations: {},

    actions: {}
};

export default apply;

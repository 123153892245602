export const finance = [
    // 财务模块
    {
        path: "/moneyManage",
        component: () => import("@/views/layout/Layout"),
        redirect: "/moneyManage",
        meta: {
            title: "资金管理",
            icon: "icon_moneyManage",
        },
        children: [
            {
                path: "account",
                component: () => import("@/views/moneyManage/account"),
                name: "account",
                meta: {
                    title: "资金账户",
                },
            },
            {
                path: "accountInfoDetails",
                component: () => import("@/views/moneyManage/accountInfoDetails"),
                name: "accountInfoDetails",
                meta: {
                    title: "账户流水明细",
                },
            },
            {
                path: "cashierCollection",
                component: () => import("@/views/moneyManage/cashierCollection"),
                name: "cashierCollection",
                meta: {
                    title: "出纳收款",
                },
            },
            {
                path: "cashierPay",
                component: () => import("@/views/moneyManage/cashierPay"),
                name: "cashierPay",
                meta: {
                    title: "出纳付款",
                },
            },
            {
                path: "moneyChange",
                component: () => import("@/views/moneyManage/moneyChange"),
                name: "moneyChange",
                meta: {
                    title: "资金调拨",
                },
            },
            {
                path: "moneyList",
                component: () => import("@/views/moneyManage/moneyList"),
                name: "moneyList",
                meta: {
                    title: "资金流水",
                },
            }
        ],
    },
    {
        path: "/qichu",
        component: () => import("@/views/layout/Layout"),
        redirect: "/qichu",
        meta: {
            title: "期初",
            icon: "icon_qichu",
        },
        children: [
            {
                path: "incomeQichu",
                component: () => import("@/views/qichu/index"),
                name: "incomeQichu",
                meta: {
                    title: "收入合同期初",
                },
            },
            {
                path: "otherIncomeQichu",
                component: () => import("@/views/qichu/index"),
                name: "otherIncomeQichu",
                meta: {
                    title: "其他收入期初",
                },
            },
            {
                path: "payQichu",
                component: () => import("@/views/qichu/index"),
                name: "payQichu",
                meta: {
                    title: "支出合同期初",
                },
            },
            {
                path: "otherPayQichu",
                component: () => import("@/views/qichu/index"),
                name: "otherPayQichu",
                meta: {
                    title: "其他支出期初",
                },
            },
        ],
    },
    {
        path: "/accounting",
        component: () => import("@/views/layout/Layout"),
        redirect: "/accounting",
        meta: {
            title: "会计处理",
            icon: "icon_accounting",
        },
        children: [
            {
                path: "taxation",
                component: () => import("@/views/accounting/taxation"),
                name: "taxation",
                meta: {
                    title: "税费",
                },
            },
            {
                path: "equityManage",
                component: () => import("@/views/accounting/equityManage"),
                name: "equityManage",
                meta: {
                    title: "股本管理",
                },
            },
            {
                path: "shareholdersManage",
                component: () => import("@/views/accounting/shareholdersManage"),
                name: "shareholdersManage",
                meta: {
                    title: "股东管理",
                },
            },
        ],
    },
    {
        path: "/reimbursement",
        component: () => import("@/views/layout/Layout"),
        redirect: "/reimbursement",
        meta: {
            title: "报销",
            icon: "icon_reimbursement",
        },
        children: [
            {
                path: "costList",
                component: () => import("@/views/reimbursement/costList"),
                name: "costList",
                meta: {
                    title: "费用列表",
                },
            },
            {
                path: "reimbursementList",
                component: () => import("@/views/reimbursement/reimbursementList"),
                name: "reimbursementList",
                meta: {
                    title: "报销单",
                },
            },
            {
                path: "employeeBorrowMoney",
                component: () => import("@/views/reimbursement/employeeBorrowMoney"),
                name: "employeeBorrowMoney",
                meta: {
                    title: "员工借款",
                },
            }
        ],
    },
    {
        path: "/approval",
        component: () => import("@/views/layout/Layout"),
        redirect: "/approval",
        meta: {
            title: "审批中心",
            icon: "icon_approval",
        },
        children: [
            {
                path: "approvalSet",
                component: () => import("@/views/approval/approvalSet/index"),
                name: "approvalSet",
                meta: {
                    title: "审批设置",
                },
            },
            {
                path: "approvalCenter",
                component: () => import("@/views/approval/approvalCenter/index"),
                name: "approvalCenter",
                meta: {
                    title: "审批中心",
                },
            }
        ],
    },
    {
        path: "/accounts",
        component: () => import("@/views/layout/Layout"),
        redirect: "/accounts",
        meta: {
            title: "往来账款",
            icon: "icon_accounts",
        },
        children: [
            {
                path: "index",
                component: () => import("@/views/accounts/index"),
                name: "accounts",
                meta: {
                    title: "预付款",
                },
            },
            {
                path: "income",
                component: () => import("@/views/accounts/index"),
                name: "income",
                meta: {
                    title: "预收款",
                },
            },
        ],
    },
    {
        path: "/incomeManage",
        component: () => import("@/views/layout/Layout"),
        redirect: "/incomeManage",
        meta: {
            title: "收入管理",
            icon: "icon_incomeManage",
        },
        children: [
            {
                path: "contractIncome",
                component: () => import("@/views/incomePayManage/contract"),
                name: "contractIncome",
                meta: {
                    title: "合同收入",
                    keepAlive: false, // 需要被缓存
                },
            },
            {
                path: "otherIncome",
                component: () => import("@/views/incomePayManage/other"),
                name: "otherIncome",
                meta: {
                    title: "其他收入",
                },
            },
            {
                path: "incomeManage",
                component: () => import("@/views/incomePayManage/incomeManage"),
                name: "incomeManage",
                meta: {
                    title: "收款管理",
                },
            },
            {
                path: "contractDetail",
                component: () => import("@/views/incomePayManage/contractDetail"),
                name: "contractDetail",
                meta: {
                    title: "合同详情",
                },
            },
        ],
    },
    {
        path: "/payManage",
        component: () => import("@/views/layout/Layout"),
        redirect: "/payManage",
        meta: {
            title: "支出管理",
            icon: "icon_payManage",
        },
        children: [
            {
                path: "contractPay",
                component: () => import("@/views/incomePayManage/contract"),
                name: "contractPay",
                meta: {
                    title: "合同支出",
                },
            },
            {
                path: "otherPay",
                component: () => import("@/views/incomePayManage/other"),
                name: "otherPay",
                meta: {
                    title: "其他支出",
                },
            },
            {
                path: "payApply",
                component: () => import("@/views/incomePayManage/payApply"),
                name: "payApply",
                meta: {
                    title: "付款管理",
                },
            },
        ],
    },
    {
        path: "/settlementManage",
        component: () => import("@/views/layout/Layout"),
        redirect: "/settlementManage",
        meta: {
            title: "支出合同结算",
            icon: "icon_deviceManage",
        },
        children: [
            {
                path: "contractSettlement",
                component: () => import("@/views/settlementManage/index"),
                name: "contractSettlement",
                meta: {
                  title: "支出合同结算",
                },
            },
        ],
    },
    {
        path: "/billManage",
        component: () => import("@/views/layout/Layout"),
        redirect: "/billManage",
        meta: {
            title: "发票管理",
            icon: "icon_billManage",
        },
        children: [
            {
                path: "billApply",
                component: () => import("@/views/billManage/billApply"),
                name: "billApply",
                meta: {
                    title: "开票申请",
                },
            },
            {
                path: "bill",
                component: () => import("@/views/billManage/bill"),
                name: "bill",
                meta: {
                    title: "开票",
                },
            },
            {
                path: "outputBill",
                component: () => import("@/views/billManage/inputBill"),
                name: "outputBill",
                meta: {
                    title: "销项发票",
                },
            },
            {
                path: "inputBill",
                component: () => import("@/views/billManage/inputBill"),
                name: "inputBill",
                meta: {
                    title: "进项发票",
                },
            },
        ],
    },
    {
        path: "/salary",
        component: () => import("@/views/layout/Layout"),
        redirect: "/salary",
        meta: {
            title: "工资",
            icon: "icon_salary",
        },
        children: [
            {
                path: "salary",
                component: () => import("@/views/salary/salary"),
                name: "salary",
                meta: {
                    title: "工资",
                },
            },
        ],
    },

];

// 人资信息管理
import {
    get,
    // post,
    // postForm,
    postJson,
} from "./http/http";

const humanResourcesManageApi = {
    // 在职员工花名册
    getOnlineUser(params) {
        return postJson("/user/user-expand/table", params);
    },

    // 取消激活
    cancelActivatedByid(userId) {
        return get(`/user/user-expand/cancelActivated/${userId}`);
    },

    // 查询用户详情
    findUserView(userId) {
        return get(`/user/user-expand/findUserView/${userId}`);
    },

    // 用户数据同步
    synchronizeData() {
        return postJson(`/user/user-expand/synchronizeData`);
    },

    // 导出花名册
    exportUserData(isLeaving) {
        return get(`/user/user-expand/exportData/${isLeaving}`);
    },

    // 薪酬列表
    getSalaryTable(params, menuId) {
        return postJson(`/salary/table/${menuId}`, params);
    },

    // 薪资详情列表
    getSalaryDetailList(params) {
        return postJson("/salary/tableByUserId", params);
    },
    // 发薪记录列表
    getSalaryHistoryTable(params) {
        return postJson("/salary/table/history", params);
    },

    // 获取个人信息根据userid
    getUserInfoByUserId(id) {
        return get(`/base/user/findById/${id}`);
    },

    // 新增个人薪酬信息
    salarySave(params) {
        return postJson(`/salary/save`, params);
    },

    // 更新个人薪酬信息
    salaryUpdate(params) {
        return postJson(`/salary/edit`, params);
    },

    // 删除个人薪酬信息
    deleteSalary(id) {
        return get(`/salary/delete/${id}`);
    },

    // 调薪记录列表
    changeSalaryList(params) {
        return postJson("/salary/change-log/table", params);
    },

    //替换头像-v3.0.0
    changeHeadPic(headPic) {
        return postJson(`/base/user/changeHeadPic/${headPic}`);
    },

    //微信解除解除绑定-v3.0.0
    unbindWeiXin() {
        return postJson(`/base/user/unbindWeiXin`);
    },

    //同步钉钉-v3.0.0
    syncDingTalkTel() {
        return postJson(`/base/user/syncDingTalkTel`);
    },

    //根据员工状态统计用户数量
    reportWorkerStatusCount() {
        return get(`/user/user-expand/reportWorkerStatusCount`);
    },

    //修改查看薪酬密码
    updateSalaryPwd(params) {
        return postJson(`/salary/updateSalaryPwd`, params);
    },
    //编辑薪酬详情 获取对应的行数据
    getSalaryDetailByid(params) {
        return postJson(`/salary/findDecryDataById`, params);
    },
    // 删除其他人员薪资
    delOtherSalary(id) {
        return get(`/salary/salary-other-user/delete/${id}`);
    },
};

export default humanResourcesManageApi;

export const homeRouter = [
    {
        path: "/",
        component: () => import("@/views/login/index"),
        redirect: "/login",
        hidden: true,
        // component: () => import("@/views/layout/Layout"),
        // redirect: "/dashboard",
        // children: [
        //     {
        //         path: "dashboard",
        //         component: () => import("@/views/dashboard/index"),
        //         name: "Dashboard",
        //         meta: {
        //             title: "主页",
        //             icon: "dashboard",
        //             noCache: true,
        //             affix: true,
        //         },
        //     },
        // ],
    },
    {
        path: "/dashboard",
        component: () => import("@/views/layout/Layout"),
        children: [
            {
                path: "dashboard",
                component: () => import("@/views/dashboard/index"),
                name: "Dashboard",
                meta: {
                    title: "主页",
                    icon: "dashboard",
                    noCache: true,
                    affix: true,
                },
            },
        ],
    },
    {
        path: "/index",
        component: () => import("@/views/layout/Layout"),
        children: [
            {
                path: "index",
                component: () => import("@/views/dashboard/index"),
                name: "Dashboard",
                meta: {
                    title: "首页",
                    icon: "dashboard",
                    noCache: true,
                    affix: true,
                },
            },
        ],
    },
];

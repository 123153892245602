// import { loginByUsername, logout, getUserInfo } from '@/api/login'

const reserveProject = {
    // namespaced: true, // 增加命名空间
    state: {
        // 项目状态
        projectStatus: [
            { code: "", label: "全部" },
            { code: "PRE_CHANCE", label: "跟进中" },
            { code: "FINISH", label: "成功" },
            { code: "SHELVE", label: "挂起" },
            { code: "FAIL", label: "战败" },
            { code: "GIVE_UP", label: "放弃" },
        ],

        // 干系人类型
        linkTypes: [
            { code: "", label: "全部" },
            { code: "CUSTOMER", label: "客户干系人" },
            { code: "COMPANY", label: "公司干系人" },
            { code: "MEDIUM", label: "中间人" },
            { code: "OTHER_PEOPLE", label: "其他干系人" },
        ],

        // 储备项目详情基础信息
        detailsInfo: {
            name: "",
            money: "", // 预估金额
            successPercent: "", // 预估成功率
            calculateMoney: "", // 折算成功率
            content: "",
            memo: "",
            customerCompanyId: "",
            customerCompanyName: "",
            customerLinkName: "",
            customerLinkPhone: "",
            createTime: "",
            startTime: "",
            planFinishTime: "",
            status: {
                text: "初步机会",
            },
        },
    },

    mutations: {
        setProjectStatus: (state, obj) => {
            state.projectStatus = obj;
        },
        setDetailsInfo: (state, obj) => {
            state.detailsInfo = obj;
        },
    },

    actions: {},
};

export default reserveProject;

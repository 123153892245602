import Vue from "vue";
import Router from "vue-router";
// import Layout from '@/views/layout/Layout'
import { homeRouter } from "@/router/module/home";
import { baseRouter } from "@/router/module/base";
import { deviceManageRouter } from "@/router/module/deviceManage";
import { projectManagementRouter } from "@/router/module/projectManagement";
import { blankPageRouter } from "@/router/module/blankPage";
import { taskManage } from "@/router/module/taskManage"; // 任务管理
import { erp } from "@/router/module/ERP3.0"; // ERP3.0
import { finance } from "@/router/module/finance"; // ERP3.8 财务模块
import { homeConfig } from "@/router/module/homeConfig"; // ERP3.10

Vue.use(Router);
/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
roles: ['admin','editor']    will control the page roles (you can set multiple roles)
title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
icon: 'svg-name'             the icon show in the sidebar
noCache: true                if true, the page will no be cached(default is false)
breadcrumb: false            if false, the item will hidden in breadcrumb(default is true)
affix: true                  if true, the tag will affix in the tags-view
}
 **/

export default new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        ...homeRouter,
        ...deviceManageRouter,
        ...baseRouter,
        ...projectManagementRouter,
        ...blankPageRouter,
        ...taskManage,
        ...erp,
        ...finance,
        ...homeConfig
    ],
});
export const asyncRouterMap = [
    // { path: '*', redirect: '/404', hidden: true }
];

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};
